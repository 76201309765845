import React, { useState, useEffect } from "react";

function Alert(props) {
  const [goout, setGoOut] = useState(1);

  useEffect(() => {
    let timeoutId;
    timeoutId = setTimeout(() => setGoOut(0), parseInt(props.timeout) - 1000);
    return () => clearTimeout(timeoutId);
  }, [props.timeout]);

  return (
    <>
      {props.alertType === 'unlock' ?
        <div
          onClick={() => {
            setGoOut(0);
          }}
          style={{ marginTop: 112 * props.idx + "px" }}
          className={`alert alert-${props.alertType}`}
          goout={goout}
        >
          <p>
            Достижение <b>{props.msg}</b> разблокировано!
          </p>
          <img className="achivmentAlertImg" src={props.img} alt="props.msg"></img>
        </div> :

        <div
          onClick={() => {
            setGoOut(0);
          }}
          style={{ marginTop: 112 * props.idx + "px" }}
          className={`alert alert-unlock`}
          goout={goout}
        >
          <p>
            Ссылка скопирована в буфер обмена <br/> <b>{props.msg}</b>
          </p>
          <i className="fas fa-share achivmentAlertImg" ></i>
        </div>

      }
    </>

  );
}

export default Alert;
