import moment from "moment";

const setAchivmentToInitial = (achivmentName) => {
  let examStats = localStorage.getItem("examStats");
  let errorsArray = localStorage.getItem("errorsArray");
  let ticketStatusArray = localStorage.getItem("ticketStatusArray");

  if (examStats) {
    examStats = JSON.parse(examStats);
  } else examStats = null;

  if (errorsArray) {
    errorsArray = JSON.parse(errorsArray);
  } else errorsArray = null;

  if (ticketStatusArray) {
    ticketStatusArray = JSON.parse(ticketStatusArray);
  } else ticketStatusArray = null;

  let checkCompleteResult;
  let forComplete;

  const checkComplete = (currentProgress, forComplete) => {
    if (currentProgress >= forComplete) return true;
    else return false;
  };

  const setDate = (isComplete) => {
    if (isComplete) return moment().format("DD/MM/YYYY");
    else return null;
  };

  const countAllErrors = (arr) => {
    let errCounter = 0;
    arr.forEach((element) => (errCounter += element.ticketErrors.length));
    return errCounter;
  };

  const countSuccessExamAttempts = (obj) => {
    let successCounter = 0;
    let sab = obj.examStatsAB.successAttemps
      ? obj.examStatsAB.successAttemps
      : 0;
    let scd = obj.examStatsCD.successAttemps
      ? obj.examStatsCD.successAttemps
      : 0;
    successCounter = sab + scd;
    return successCounter;
  };
  const countFailExamAttempts = (obj) => {
    let failCounter = 0;
    let fab = obj.examStatsAB.failAttempts
      ? obj.examStatsAB.failAttempts
      : 0;
    let fcd = obj.examStatsCD.failAttempts
      ? obj.examStatsCD.failAttempts
      : 0;
    failCounter = fab + fcd;
    return failCounter;
  };

  const makeObj = (
    defaultSet = false,
    forComplete,
    currentProgress,
    isComplete,
    date
  ) => {
    if (defaultSet) {
      return {
        forComplete: forComplete,
        currentProgress: 0,
        isComplete: false,
        date: null,
      };
    } else
      return {
        forComplete: forComplete,
        currentProgress: currentProgress,
        isComplete: isComplete,
        date: date,
      };
  };

  switch (achivmentName) {
    case "niceStart":
      forComplete = 10;
      if (ticketStatusArray) {
        checkCompleteResult = checkComplete(
          ticketStatusArray.length,
          forComplete
        );
        return makeObj(
          false,
          forComplete,
          ticketStatusArray.length,
          checkCompleteResult,
          setDate(checkCompleteResult)
        );
      } else return makeObj(true, forComplete); 
    case "keepItUp":
      forComplete = 20;
      if (ticketStatusArray) {
        checkCompleteResult = checkComplete(
          ticketStatusArray.length,
          forComplete
        );
        return makeObj(
          false, //defaultSet
          forComplete, //forComplete
          ticketStatusArray.length, //currentProgress
          checkCompleteResult, //isComplete
          setDate(checkCompleteResult) //date
        );
      } else return makeObj(true, forComplete);
    case "forty":
      forComplete = 40;
      if (ticketStatusArray) {
        checkCompleteResult = checkComplete(
          ticketStatusArray.length,
          forComplete
        );
        return makeObj(
          false, //defaultSet
          forComplete, //forComplete
          ticketStatusArray.length, //currentProgress
          checkCompleteResult, //isComplete
          setDate(checkCompleteResult) //date
        );
      } else return makeObj(true, forComplete);
    case "masterAB":
      forComplete = 40;
      if (ticketStatusArray) {
        const ticketStatusArrayABCorrect = ticketStatusArray.filter(
          (el) => el.ticketCategory === "ticketsAB" && el.ticketStatus === true
        );
        checkCompleteResult = checkComplete(
          ticketStatusArrayABCorrect.length,
          forComplete
        );
        return makeObj(
          false, //defaultSet
          forComplete, //forComplete
          ticketStatusArrayABCorrect.length, //currentProgress
          checkCompleteResult, //isComplete
          setDate(checkCompleteResult) //date
        );
      } else return makeObj(true, forComplete);
    case "masterCD":
      forComplete = 40;
      if (ticketStatusArray) {
        const ticketStatusArrayCDCorrect = ticketStatusArray.filter(
          (el) => el.ticketCategory === "ticketsCD" && el.ticketStatus === true
        );
        checkCompleteResult = checkComplete(
          ticketStatusArrayCDCorrect.length,
          forComplete
        );
        return makeObj(
          false, //defaultSet
          forComplete, //forComplete
          ticketStatusArrayCDCorrect.length, //currentProgress
          checkCompleteResult, //isComplete
          setDate(checkCompleteResult) //date
        );
      } else return makeObj(true, forComplete);
    case "badAss":
      forComplete = 300;
      if (errorsArray) {
        const allErrorsCounter = countAllErrors(errorsArray);
        checkCompleteResult = checkComplete(allErrorsCounter, forComplete);
        return makeObj(
          false, //defaultSet
          forComplete, //forComplete
          allErrorsCounter, //currentProgress
          checkCompleteResult, //isComplete
          setDate(checkCompleteResult) //date
        );
      } else return makeObj(true, forComplete);
    case "excellentKnowledge":
      forComplete = 50;
      if (examStats) {
        const successCounter = countSuccessExamAttempts(examStats);
        checkCompleteResult = checkComplete(successCounter, forComplete);
        return makeObj(
          false, //defaultSet
          forComplete, //forComplete
          successCounter, //currentProgress
          checkCompleteResult, //isComplete
          setDate(checkCompleteResult) //date
        );
      } else return makeObj(true, forComplete);
    case "hundredExams":
      forComplete = 100;
      if (examStats) {
        const totalCounter =
          countSuccessExamAttempts(examStats) +
          countFailExamAttempts(examStats);
        checkCompleteResult = checkComplete(totalCounter, forComplete);
        return makeObj(
          false, //defaultSet
          forComplete, //forComplete
          totalCounter, //currentProgress
          checkCompleteResult, //isComplete
          setDate(checkCompleteResult) //date
        );
      } else return makeObj(true, forComplete);
    case "friend":
      forComplete = 1;
      return makeObj(true, forComplete);
    case "errorsResolve":
      forComplete = 100;
      return makeObj(true, forComplete);
    case "fast":
      forComplete = 1;
      return makeObj(true, forComplete);
    case "superFast":
      forComplete = 1;
      return makeObj(true, forComplete);

    default:
      break;
  }
};
export default setAchivmentToInitial;
