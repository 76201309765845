import React from "react";
/* import ReactDOM from "react-dom"; */
import { hydrate, render } from "react-dom";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import achivmentsData from "./main/components/Achivments/achivmentsData";
import setAchivmentToInitial from "./main/components/Achivments/setAchivmentToInitial.js";

import * as serviceWorker from "./serviceWorker";
import configureSecondsStore from "./hooks-store/seconds-store";

import configureStore, { history } from "./store/configureStore";

configureSecondsStore();

let achivmentsLSObj = localStorage.getItem("achivments");
if (!achivmentsLSObj) {
  achivmentsLSObj = {};
  for (let i = 0; i < achivmentsData.length; i++) {
    achivmentsLSObj[achivmentsData[i].name] = setAchivmentToInitial(
      achivmentsData[i].name
    );
  }
  localStorage.setItem("achivments", JSON.stringify(achivmentsLSObj));
}



const rootElement = document.getElementById("root");


const store = configureStore(/* preloadedState */);


if (rootElement.hasChildNodes()) {
  hydrate(<Provider store={store}>
    <ConnectedRouter history={history}>
        <App />
    </ConnectedRouter>
  </Provider>, rootElement);
} else {
  render(<Provider store={store}>
    <ConnectedRouter history={history}>
        <App />
    </ConnectedRouter>
  </Provider>, rootElement);
}


/* ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
); */

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
