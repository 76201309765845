import React from "react";

const Idx1ArticleSection = () => {
    return (
        <React.Fragment>
            <div id="yandex_rtb_R-A-1691059-51"></div>
            <script>
                {window.yaContextCb.push(() => {
                    window.Ya.Context.AdvManager.render({
                        blockId: "R-A-1691059-51",
                        renderTo: "yandex_rtb_R-A-1691059-51"
                    })
                })}</script>
        </React.Fragment>
    )
}

const Idx2ArticleSection = () => {
    return (
        <React.Fragment>
            <div id="yandex_rtb_R-A-1691059-52"></div>
            <script>
                {window.yaContextCb.push(() => {
                    window.Ya.Context.AdvManager.render({
                        blockId: "R-A-1691059-52",
                        renderTo: "yandex_rtb_R-A-1691059-52"
                    })
                })}</script>
        </React.Fragment>
    )
}

const Idx5ArticleSection = () => {
    return (
        <React.Fragment>
            <div id="yandex_rtb_R-A-1691059-53"></div>
            <script>
                {window.yaContextCb.push(() => {
                    window.Ya.Context.AdvManager.render({
                        blockId: "R-A-1691059-53",
                        renderTo: "yandex_rtb_R-A-1691059-53"
                    })
                })}</script>
        </React.Fragment>
    )
}

const Idx11ArticleSection = () => {
    return (
        <React.Fragment>
            <div id="yandex_rtb_R-A-1691059-54"></div>
            <script>
                {window.yaContextCb.push(() => {
                    window.Ya.Context.AdvManager.render({
                        blockId: "R-A-1691059-54",
                        renderTo: "yandex_rtb_R-A-1691059-54"
                    })
                })}</script>
        </React.Fragment>
    )
}

const Idx14ArticleSection = () => {
    return (
        <React.Fragment>
            <div id="yandex_rtb_R-A-1691059-55"></div>
            <script>
                {window.yaContextCb.push(() => {
                    window.Ya.Context.AdvManager.render({
                        blockId: "R-A-1691059-55",
                        renderTo: "yandex_rtb_R-A-1691059-55"
                    })
                })}</script>
        </React.Fragment>
    )
}

const Idx20ArticleSection = () => {
    return (
        <React.Fragment>
            <div id="yandex_rtb_R-A-1691059-56"></div>
            <script>
                {window.yaContextCb.push(() => {
                    window.Ya.Context.AdvManager.render({
                        blockId: "R-A-1691059-56",
                        renderTo: "yandex_rtb_R-A-1691059-56"
                    })
                })}</script>
        </React.Fragment>
    )
}

const Idx33ArticleSection = () => {
    return (
        <React.Fragment>
            <div id="yandex_rtb_R-A-1691059-57"></div>
            <script>
                {window.yaContextCb.push(() => {
                    window.Ya.Context.AdvManager.render({
                        blockId: "R-A-1691059-57",
                        renderTo: "yandex_rtb_R-A-1691059-57"
                    })
                })}</script>
        </React.Fragment>
    )
}

const Idx37ArticleSection = () => {
    return (
        <React.Fragment>
            <div id="yandex_rtb_R-A-1691059-58"></div>
            <script>
                {window.yaContextCb.push(() => {
                    window.Ya.Context.AdvManager.render({
                        blockId: "R-A-1691059-58",
                        renderTo: "yandex_rtb_R-A-1691059-58"
                    })
                })}</script>
        </React.Fragment>
    )
}

const Idx56ArticleSection = () => {
    return (
        <React.Fragment>
            <div id="yandex_rtb_R-A-1691059-59"></div>
            <script>
                {window.yaContextCb.push(() => {
                    window.Ya.Context.AdvManager.render({
                        blockId: "R-A-1691059-59",
                        renderTo: "yandex_rtb_R-A-1691059-59"
                    })
                })}</script>
        </React.Fragment>
    )
}

const Idx59ArticleSection = () => {
    return (
        <React.Fragment>
            <div id="yandex_rtb_R-A-1691059-60"></div>
            <script>
                {window.yaContextCb.push(() => {
                    window.Ya.Context.AdvManager.render({
                        blockId: "R-A-1691059-60",
                        renderTo: "yandex_rtb_R-A-1691059-60"
                    })
                })}</script>
        </React.Fragment>
    )
}

const Idx70ArticleSection = () => {
    return (
        <React.Fragment>
            <div id="yandex_rtb_R-A-1691059-61"></div>
            <script>
                {window.yaContextCb.push(() => {
                    window.Ya.Context.AdvManager.render({
                        blockId: "R-A-1691059-61",
                        renderTo: "yandex_rtb_R-A-1691059-61"
                    })
                })}</script>
        </React.Fragment>
    )
}

const Idx82ArticleSection = () => {
    return (
        <React.Fragment>
            <div id="yandex_rtb_R-A-1691059-62"></div>
            <script>
                {window.yaContextCb.push(() => {
                    window.Ya.Context.AdvManager.render({
                        blockId: "R-A-1691059-62",
                        renderTo: "yandex_rtb_R-A-1691059-62"
                    })
                })}</script>
        </React.Fragment>
    )
}

const Idx93ArticleSection = () => {
    return (
        <React.Fragment>
            <div id="yandex_rtb_R-A-1691059-63"></div>
            <script>
                {window.yaContextCb.push(() => {
                    window.Ya.Context.AdvManager.render({
                        blockId: "R-A-1691059-63",
                        renderTo: "yandex_rtb_R-A-1691059-63"
                    })
                })}</script>
        </React.Fragment>
    )
}

const adsObjArticleSection = {
    1: <Idx1ArticleSection />,
    2: <Idx2ArticleSection />,
    5: <Idx5ArticleSection />,
    11: <Idx11ArticleSection />,
    14: <Idx14ArticleSection />,
    20: <Idx20ArticleSection />,
    33: <Idx33ArticleSection />,
    37: <Idx37ArticleSection />,
    56: <Idx56ArticleSection />,
    59: <Idx59ArticleSection />,
    70: <Idx70ArticleSection />,
    82: <Idx82ArticleSection />,
    93: <Idx93ArticleSection />,
}

export default adsObjArticleSection;
