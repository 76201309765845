import React from 'react';
import { Helmet } from 'react-helmet';

const HelmetOneVideo = ({ video, category, videoId }) => {

    const createTitle = (title, channelName) => {
        if (channelName) { return `${title} | ${channelName} | ПДД Портал`; }
        else { return `${title} | ПДД Портал`; }
    }

    const createDescription = (description) => {
        return `Сайт про ПДД РФ: Видео, Тесты, Экзамен, Теория, Дорожные Знаки и Разметка - Готовьтесь к сдаче на водительские права! ${description} | PDDPORTAL.RU`;
    }

    return (<Helmet>
        <title>{video && createTitle(video.title, video.channelName)}</title>
        <meta name="description" content={video && createDescription(video.description)} />
        <meta name="keywords" content={video && createTitle(video.title, video.channelName)} />

        <meta property="og:title" content={video && createTitle(video.title, video.channelName)} />
        <meta property="og:description" content={video && createDescription(video.description)} />
        <meta property="og:image" content={video && video.thumbnailUrl} />
        <meta property="og:url" content={`https://pddportal.ru/category/${category}/${videoId}/`} />
        <meta property="og:site_name" content="PDDPORTAL.RU" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="ru_RU" />

        <link rel="canonical" href={`https://pddportal.ru/category/${category}/${videoId}/`} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={video && createTitle(video.title, video.channelName)} />
        <meta name="twitter:description" content={video && createDescription(video.description)} />
        <meta name="twitter:image" content={video && video.thumbnailUrl} />
        <meta name="twitter:url" content={`https://pddportal.ru/category/${category}/${videoId}/`} />
        <meta name="twitter:site" content="@pddportal" />
        <meta name="twitter:creator" content="@pddportal" />
        <meta name="twitter:image:alt" content={video && createTitle(video.title, video.channelName)} />
        <meta name="twitter:domain" content="pddportal.ru" />

        <meta name='author' content={video && video.channelName} />

    </Helmet>
    );
}

export default HelmetOneVideo;
