import React from 'react';
import { Link } from 'react-router-dom';
import { createDateFromTimestamp } from '../../shared/functions/utilities/createDateFromTimestamp';

import './articleSnippet.css'

import { setCurrentArticle } from '../../store/actions/articleActions';
import { useDispatch } from 'react-redux';

function ArticleSnippet({ snippet }) {
    const dispatch = useDispatch();

    const handleClick = () => {
        window.scrollTo(0, 0);
        const article = {
            title: snippet.title,
            description: snippet.description,
            image: snippet.image,
            urlName: snippet.urlName,
            markdown: snippet.markdown,
            author: { name: snippet.authorName, avatar: snippet.authorAvatar },
            date: snippet.date,
            hideFromOffset: snippet.index && snippet.index + 1,
        }
        dispatch(setCurrentArticle(article));
    }

    return (
        <article className="article-card">
            {/* <div className="article-shadow"> </div> */}
            {snippet.image &&
                <Link onClick={handleClick} to={`/articles/${snippet.sectionName}/${snippet.urlName}`}>
                    <img className="article-img-box" src={snippet.image} alt={snippet.title} />
                </Link>

            }
            <div className="article-content">

                <Link onClick={handleClick} to={`/articles/${snippet.sectionName}/${snippet.urlName}`}>
                    <h3 style={{ marginTop: 0 }} className="article-title">{snippet.title}</h3>
                </Link>

                <p className="article-text">{snippet.description}</p>

                <div className="acticle-content-footer">

                    <div className="author">
                        <img src={snippet.authorAvatar ? snippet.authorAvatar : "/images/icons/logo192.png"} alt="аватар автора статьи" className="author-avatar" />
                        <div className="author-info">
                            <h4 style={{ margin: 0 }} className="author-name">{snippet.authorName ? snippet.authorName : "Pddportal"}</h4>
                            <div className="publish-date">{snippet.date && createDateFromTimestamp(snippet.date)}</div>
                        </div>
                    </div>


                    <div className="share">
                        <Link onClick={handleClick} to={`/articles/${snippet.sectionName}/${snippet.urlName}`}>
                            <button className="share-button">
                                Читать полностью
                            </button>
                        </Link>

                    </div>

                </div>

            </div>

        </article>
    );
}


export default ArticleSnippet;

