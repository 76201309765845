import React from 'react';

function OneVideoSkeleton(props) {
    return (
        <div>
            <div className='one-video-layout'>
                <div className='video-player skeleton'>
                </div>
                <div className='video-player-info skeleton'>
                </div>
                <div className='related-videos skeleton'>
                    <div></div>
                </div>
            </div>
        </div>
    );
}

export default OneVideoSkeleton;