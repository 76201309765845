import axios from "axios";
import {
    SET_CURRENT_ARTICLE,
    GET_SECTION_ARTICLES_REQUEST,
    GET_SECTION_ARTICLES_SUCCESS,
    GET_SECTION_ARTICLES_FAIL,
    CLEAR_SECTION_ARTICLES,
    GET_SECTION_ARTICLE_REQUEST,
    GET_SECTION_ARTICLE_SUCCESS,
    GET_SECTION_ARTICLE_FAIL,
    GET_RANDOM_ARTICLES_FAIL,
    GET_RANDOM_ARTICLES_SUCCESS,
    GET_RANDOM_ARTICLES_REQUEST,
    CLEAR_RANDOM_ARTICLES,
    CLEAR_HALF_RANDOM_ARTICLES
} from "../constants/articleConstants";

export const setCurrentArticle = (article) => (dispatch) => {
    dispatch({
        type: SET_CURRENT_ARTICLE,
        payload: article,
    });
}

export const getSectionArticles = (sectionName, offset) => async (dispatch, getState) => {
    const { sectionArticlesReducer } = getState();
    const { cancelToken } = sectionArticlesReducer;

    if (cancelToken) {
        cancelToken.cancel('canceled by the user');
    }

    const newCancelToken = axios.CancelToken.source();

    try {
        dispatch({
            type: GET_SECTION_ARTICLES_REQUEST,
            payload: { cancelToken: newCancelToken }
        });

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
            params: {
                offset
            },
            cancelToken: newCancelToken.token
        };

        const { data } = await axios.get(`/api/articles/${sectionName}`, config);

        dispatch({
            type: GET_SECTION_ARTICLES_SUCCESS,
            payload: { ...data, sectionName }
        });

    } catch (error) {
        if (axios.isCancel(error)) {
            console.log('Request canceled', error.message);
        }
        else {
            dispatch({
                type: GET_SECTION_ARTICLES_FAIL,
                payload: error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
            });
        }
    }
}

export const getSectionArticlesInitial = (sectionName) => async (dispatch, getState) => {
    try {
        const { sectionArticlesReducer } = getState();
        const { sectionName: sectionNameRedux } = sectionArticlesReducer;
        const { cancelToken } = sectionArticlesReducer;
        const { articles } = sectionArticlesReducer;

        if (sectionName === sectionNameRedux && articles.length > 0) {
            return;
        }

        if (cancelToken) {
            cancelToken.cancel('canceled by the user');
        }

        const newCancelToken = axios.CancelToken.source();

        dispatch({ type: CLEAR_SECTION_ARTICLES });
        dispatch({ type: CLEAR_RANDOM_ARTICLES });

        dispatch({
            type: GET_SECTION_ARTICLES_REQUEST,
            payload: { cancelToken: newCancelToken }
        });

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
            cancelToken: newCancelToken.token
        };

        const { data } = await axios.get(`/api/articles/${sectionName}`, config);

        dispatch({
            type: GET_SECTION_ARTICLES_SUCCESS,
            payload: { ...data, sectionName, cancelToken: newCancelToken }
        });

    } catch (error) {
        if (axios.isCancel(error)) {
            console.log('Request canceled', error.message);
        }
        else {
            dispatch({
                type: GET_SECTION_ARTICLES_FAIL,
                payload: error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
            });
        }
    }
}

export const getOneSectionArticleById = (sectionName, urlName) => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_SECTION_ARTICLE_REQUEST,
        });

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };

        const { data } = await axios.get(`/api/articles/${sectionName}/${urlName}`, config);

        dispatch({
            type: GET_SECTION_ARTICLE_SUCCESS,
            payload: data
        });

    } catch (error) {
        dispatch({
            type: GET_SECTION_ARTICLE_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        });
    }
}

export const getRandomArticles = (offset) => async (dispatch, getState) => {

    try {
        const { sectionArticlesReducer, randomArticlesReducer } = getState();
        const { articles: sectionArticles } = sectionArticlesReducer;
        const { articles: randomArticles } = randomArticlesReducer;

        dispatch({
            type: GET_RANDOM_ARTICLES_REQUEST,
        });

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
            params: {
                alreadyLoadedQuantity: sectionArticles.length + randomArticles.length,
            }

        };

        const { data } = await axios.get(`/api/videoSections/random`, config);

        const sectionArticlesIds = sectionArticles.map(article => article.id);
        const randomArticlesIds = randomArticles.map(article => article.id);

        const alreadyLoaded = [...sectionArticlesIds, ...randomArticlesIds];

        const filteredArticles = await data.articles.filter(article => !alreadyLoaded.includes(article.id));

        if (filteredArticles.length <= 3) {
            /* console.log('less than 3, clearing half') */
            dispatch({
                type: CLEAR_HALF_RANDOM_ARTICLES,
            });
            return;
        }

        dispatch({
            type: GET_RANDOM_ARTICLES_SUCCESS,
            payload: {
                ...data,
                articles: filteredArticles
            }
        });

    } catch (error) {
        dispatch({
            type: GET_RANDOM_ARTICLES_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        });
    }
}