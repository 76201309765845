import React from 'react';
import { Helmet } from 'react-helmet';

function HelmetVideoSection(props) {
    return (
        <>
            <Helmet>
                <title> Подборка видео на автомобильные темы: Автошкола, Разбор ДТП, Устройство автомобилей, Автошоу, Автоблоггеры, Автоюристы - PDDPORTAL.RU </title>
                <meta name="description" content="Видеораздел сайта PDDPORTAL.RU. Тесты, Экзамен, Теория, Дорожные Знаки и Разметка - Готовьтесь к сдаче на водительские права! Ознакомьтесь с последними изменениями в Правилах Дорожного Движения России" />
                <meta name="keywords" content="ПДД, ПДД РФ, Экзамен на права, автобилеты, билеты как в ГАИ, ПДД 2024, ПДД 2024, теория ПДД, видео ПДД, пдд, автошкола" />

                <meta property="og:title" content="Подборка видео на автомобильные темы: Автошкола, Разбор ДТП, Устройство автомобилей, Автошоу, Автоблоггеры, Автоюристы - PDDPORTAL.RU " />
                <meta property="og:description" content="Видеораздел сайта PDDPORTAL.RU. Тесты, Экзамен, Теория, Дорожные Знаки и Разметка - Готовьтесь к сдаче на водительские права! Ознакомьтесь с последними изменениями в Правилах Дорожного Движения России" />
                <meta property="og:image" content="/images/icons/logo192.png"></meta>
                <meta property="og:image:width" content="192" />
                <meta property="og:image:height" content="192" />
                <meta property="og:url" content={`https://pddportal.ru/videos/`} />
                <meta property="og:site_name" content="PDDPORTAL.RU" />
                <meta property="og:type" content="website" />
                <meta property="og:locale" content="ru_RU" />
                <link rel="canonical" href={`https://pddportal.ru/videos/`} />
            </Helmet>

            {props.children}
        </>
    );
}

export default HelmetVideoSection;