import achivmentsData from "../../../main/components/Achivments/achivmentsData";
const achivmentsAlertHandler = (achivmentsNames, setAlert) => {
  if (achivmentsNames && achivmentsNames.length > 0) {
    const achivmentsToAlert = achivmentsData.filter(({ name }) =>
      achivmentsNames.includes(name)
    );
    achivmentsToAlert.map((achv) =>
      setAlert(achv.header, "unlock", 6000, achv.url)
    );
  } 
};

export default achivmentsAlertHandler;
