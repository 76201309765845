export const SET_CURRENT_VIDEO = 'SET_CURRENT_VIDEO';
export const GET_SECTION_VIDEOS_REQUEST = 'GET_SECTION_VIDEOS_REQUEST';
export const GET_SECTION_VIDEOS_SUCCESS = 'GET_SECTION_VIDEOS_SUCCESS';
export const GET_SECTION_VIDEOS_FAIL = 'GET_SECTION_VIDEOS_FAIL';
export const CLEAR_SECTION_VIDEOS = 'CLEAR_SECTION_VIDEOS';
export const ADD_OFFSET_TO_SECTION_VIDEOS = 'ADD_OFFSET_TO_SECTION_VIDEOS';

export const GET_SECTION_VIDEO_REQUEST = 'GET_SECTION_VIDEO_REQUEST';
export const GET_SECTION_VIDEO_SUCCESS = 'GET_SECTION_VIDEO_SUCCESS';
export const GET_SECTION_VIDEO_FAIL = 'GET_SECTION_VIDEO_FAIL';

export const GET_RANDOM_VIDEOS_REQUEST = 'GET_RANDOM_VIDEOS_REQUEST';
export const GET_RANDOM_VIDEOS_SUCCESS = 'GET_RANDOM_VIDEOS_SUCCESS';
export const GET_RANDOM_VIDEOS_FAIL = 'GET_RANDOM_VIDEOS_FAIL';
export const CLEAR_RANDOM_VIDEOS = 'CLEAR_RANDOM_VIDEOS';
export const CLEAR_HALF_RANDOM_VIDEOS = 'CLEAR_HALF_RANDOM_VIDEOS';