// highlightsReducer.js
import {
    GET_HIGHLIGHTS_REQUEST,
    GET_HIGHLIGHTS_SUCCESS,
    GET_HIGHLIGHTS_FAIL,
    SET_ANSWERED_INDEX,
} from "./constants/highlightsConstants";

const initialState = {
    highlights: null,
    loading: false,
    error: null,
};

export const highlightsReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_HIGHLIGHTS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                highlights: null
            };
        case GET_HIGHLIGHTS_SUCCESS:
            return {
                ...state,
                loading: false,
                highlights: payload,
                error: null
            };
        case GET_HIGHLIGHTS_FAIL:
            return {
                ...state,
                loading: false,
                error: payload,
            };

        case SET_ANSWERED_INDEX:
            // Assuming payload contains { storyId, answeredIndex }
            const { storyId, answeredIndex } = payload;

            // Update highlights by mapping over each category
            const updatedHighlights = Object.keys(state.highlights).reduce((acc, category) => {
                // Map over stories in each category to find and update the correct one
                const updatedStories = state.highlights[category].map(story => {
                    if (story.storyId === storyId) {
                        return { ...story, answeredIndex }; // Update the matched story's answeredIndex
                    }
                    return story; // Return the story unchanged if it's not the one we're looking for
                });

                // Return the accumulated highlights with updated stories for the current category
                return { ...acc, [category]: updatedStories };
            }, {});

            // Return the updated state
            return {
                ...state,
                highlights: updatedHighlights
            };

        default:
            return state;
    }
}