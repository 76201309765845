import React, { useEffect } from 'react';
import { VideoPreviewMaxi } from './components/VideoPreview';
import { useDispatch, useSelector } from 'react-redux';
import { getSectionVideos, getSectionVideosInitial } from '../store/actions/videoActions';
/* import { CLEAR_SECTION_VIDEOS } from '../store/constants/videoConstants'; */
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from '../shared/components/Loader';
import GridVideoSkeletonLoader from '../shared/components/GridVideoSkeletonLoader';
import { SPECIAL_INDEXIES as specialIndexies } from '../constants/otherConstants'

import adsObjVideoSection from './components/AdVideoSection'
import { useParams } from 'react-router-dom';

import './VideoSection.css';
import './components/video.css';

function VideoSection({ limitForShow, shouldShuffle }) {
    const { category } = useParams();
    const dispatch = useDispatch();

    const { loading, videos, error, hasMore } = useSelector((state) => state.sectionVideosReducer);
    let displayedVideos;

    if (limitForShow && limitForShow < videos.length) {
        displayedVideos = videos.slice(0, limitForShow);
    }

    if (shouldShuffle) {
        if (Array.isArray(displayedVideos) && displayedVideos.length > 0) {
            displayedVideos = displayedVideos.sort(() => Math.random() - 0.5);
        }
        else displayedVideos = videos.sort(() => Math.random() - 0.5);
    }

    useEffect(() => {
        const offset = 0;
        dispatch(getSectionVideosInitial(category, offset));
    }, [dispatch, category]);

    const fetchMoreVideos = () => {
        const offset = videos.length;
        dispatch(getSectionVideos(category, offset));
    };

    return (
        <>
            {loading && videos.length === 0 && <GridVideoSkeletonLoader />}
            {
                limitForShow && <div className='video-section'>
                    {displayedVideos && displayedVideos.map((video, index) => {
                        if (specialIndexies.includes(index)) {
                            return (
                                <React.Fragment key={video.id.videoId}>
                                    <div className='special-preview-block video-preview-maxi'>
                                        {adsObjVideoSection[index]}
                                    </div>
                                    <VideoPreviewMaxi
                                        thumbnailUrl={video.snippet.thumbnails.medium.url}
                                        title={video.snippet.title}
                                        channelName={video.snippet.channelTitle}
                                        publishedAt={video.snippet.publishTime}
                                        videoId={video.id.videoId}
                                        description={video.snippet.description}
                                        sectionName={category}
                                    />
                                </React.Fragment>
                            );
                        } else {
                            return <VideoPreviewMaxi
                                key={video.id.videoId}
                                thumbnailUrl={video.snippet.thumbnails.medium.url}
                                title={video.snippet.title}
                                channelName={video.snippet.channelTitle}
                                publishedAt={video.snippet.publishTime}
                                videoId={video.id.videoId}
                                description={video.snippet.description}
                                sectionName={category}
                            />
                        }
                    })}
                </div>
            }

            {!limitForShow &&
                <InfiniteScroll
                    dataLength={(videos.length) || 0} // This is important
                    next={fetchMoreVideos}
                    hasMore={hasMore}
                    children={videos}
                    loader={<Loader
                        type="Oval"
                        color="#00BFFF"
                        height={90}
                        width={90}
                        style={{ margin: "30px auto", display: "block", textAlign: "center" }}
                    />}
                    endMessage={<p>No more videos to load</p>}

                >
                    <div className='video-section'>
                        {videos && videos.map((video, index) => {
                            if (specialIndexies.includes(index)) {
                                return (
                                    <React.Fragment key={video.id.videoId}>
                                        <div className='special-preview-block video-preview-maxi'>
                                            {adsObjVideoSection[index]}
                                        </div>
                                        <VideoPreviewMaxi
                                            thumbnailUrl={video.snippet.thumbnails.medium.url}
                                            title={video.snippet.title}
                                            channelName={video.snippet.channelTitle}
                                            publishedAt={video.snippet.publishTime}
                                            videoId={video.id.videoId}
                                            description={video.snippet.description}
                                            sectionName={category}
                                            videosArrLength={videos.length}
                                        />
                                    </React.Fragment>
                                );
                            } else {
                                return <VideoPreviewMaxi
                                    key={video.id.videoId}
                                    thumbnailUrl={video.snippet.thumbnails.medium.url}
                                    title={video.snippet.title}
                                    channelName={video.snippet.channelTitle}
                                    publishedAt={video.snippet.publishTime}
                                    videoId={video.id.videoId}
                                    description={video.snippet.description}
                                    sectionName={category}
                                    videosArrLength={videos.length}
                                />
                            }
                        }
                        )}
                    </div>
                </InfiniteScroll>
            }
            {error && <div>{error}</div>}
        </>
    );
}

export default VideoSection;