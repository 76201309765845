/* eslint-disable default-case */
import * as actionTypes from "./actions";

function getInitialState() {
  return {
    clickedAnswerId: null,
    numbersStateArray: [],
    ticketStatusArray: JSON.parse(localStorage.getItem("ticketStatusArray")),
    ticketsArray: [],
    loadedBilet: null,
    shouldResetTimer: false,
    shouldAddFiveMinutes: false,
    shouldUpdateExamStats: false,
    deadline: 1200,
    timerStartsAt: 0
  };
}

const ticketsReducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case actionTypes.SET_CLICKED_ANSWER_ID:
      return {
        ...state,
        clickedAnswerId: action.clickedAnswerId,
      };
    case actionTypes.SET_NUMBERS_STATE_ARRAY:
      return {
        ...state,
        numbersStateArray: [
          ...state.numbersStateArray,
          {
            id: action.arrayItem.id,
            isError: action.arrayItem.isError,
            clickedAnswer: action.arrayItem.clickedAnswer,
            ticketId: action.arrayItem.ticketId,
            taskId: action.arrayItem.taskId,
          },
        ],
      };
    case actionTypes.RESET_NUMBERS_STATE_ARRAY:
      return {
        ...state,
        numbersStateArray: [],
      };
    case actionTypes.SET_TICKET_STATUS_ARRAY:
      return {
        ...state,
        ticketStatusArray: action.ticketStatusArray
      };
    case actionTypes.RESET_TICKET_STATUS_ARRAY:
      return {
        ...state,
        ticketStatusArray: null,
      };
    case actionTypes.SET_TICKETS_ARRAY:
      return {
        ...state,
        ticketsArray: action.ticketsArray,
      };
    case actionTypes.SET_LOADED_BILET:
      return {
        ...state,
        loadedBilet: action.loadedBilet,
      };
    case actionTypes.RESET_TO_INITIAL_STATE:
      return getInitialState();
    case actionTypes.SET_SHOULD_RESET_TIMER:
      return {
        ...state,
        shouldResetTimer: action.shouldResetTimer,
      };
    case actionTypes.SET_SHOULD_ADD_FIVE_MINUTES:
      return {
        ...state,
        shouldAddFiveMinutes: action.shouldAddFiveMinutes,
      };
    case actionTypes.SET_SHOULD_UPDATE_EXAM_STATS:
      return {
        ...state,
        shouldUpdateExamStats: action.shouldUpdateExamStats,
      };
    case actionTypes.SET_RESULT_TIMER:
      return {
        ...state,
        resultTimer: action.resultTimer,
      };
    case actionTypes.SET_DEADLINE:
      return {
        ...state,
        deadline: action.deadline,
      };
      case actionTypes.SET_TIMER_STARTS_AT:
      return {
        ...state,
        timerStartsAt: action.timerStartsAt,
      };
  }
  return state;
};

export default ticketsReducer;
