import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import ReadingTimeComponent from './ReadingTimeComponent';
import './articles.css'

import { createDateFromTimestamp } from '../../shared/functions/utilities/createDateFromTimestamp';

const TableOfContents = ({ headings, article, author, date }) => {

  return (
    <div>
      <h1 className='toc-heading'>{headings.length > 0 && headings[0].value}</h1>

      <div className='toc-fishki'>
        <div className="author">
          {author && author.avatar && <img src={author.avatar} alt="" className="author-avatar" />}
          <div className="author-info">
            <h4 style={{ margin: 0 }} className="author-name">{author && author.name && author.name}</h4>
            <div className="publish-date">{date && createDateFromTimestamp(date)}</div>
          </div>
        </div>

        <h4 style={{ color: "grey" }}>
          <ReadingTimeComponent article={article.markdown} />
        </h4>
      </div>

      {headings && headings.length > 0 &&
        <div className="toc">
          <ul className='toc-ul'>
            {headings.map((heading, idx) => (
              idx !== 0 &&
              <li className='toc-li' key={idx}>
                <Link to={`#${heading.id}`}>{heading.value}</Link>
              </li>
            ))}
          </ul>
        </div>
      }
    </div>
  );
};

export default TableOfContents;


