import { initStore } from "./store";

const configureStore = () => {
  const actions = {
    SECONDS_HANDLER: (curState, seconds) => {
      return { seconds: seconds };
    },
    TIMER_RAISE: (curState, deadline) => {
      return { deadline: deadline };
    },
  };
  initStore(actions, {});
};

export default configureStore;
