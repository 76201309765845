import React from "react";

import ContextMenu from "../shared/Navigation/ContextMenu";
/* import VideoSectionRandom from "../video-section/VideoSectionRandom"; */

import "../App.css";


const ArticlesLayout = (props) => {
  return (
    <>

      <div className="grid-container">
        <ContextMenu notRenderBurgerMenuIcon={true}>{() => { }}</ContextMenu>


        <div className="dash-board">
          {props.children}
        </div>

        <div className="articles-side-bar side-bar">

          <div className="articles-sidebar-block">
            <div id="yandex_rtb_R-A-1691059-64"></div>
            <script>
              {window.yaContextCb.push(() => {
                window.Ya.Context.AdvManager.render({
                  blockId: "R-A-1691059-64",
                  renderTo: "yandex_rtb_R-A-1691059-64"
                })
              })}</script>
          </div>

          <div className="articles-sidebar-block">
            <div id="yandex_rtb_R-A-1691059-65"></div>
            <script>
              {window.yaContextCb.push(() => {
                window.Ya.Context.AdvManager.render({
                  blockId: "R-A-1691059-65",
                  renderTo: "yandex_rtb_R-A-1691059-65"
                })
              })}</script>
          </div>

          <div className="articles-sticky-sidebar-block">
            <div id="yandex_rtb_R-A-1691059-66"></div>
            <script>
              {window.yaContextCb.push(() => {
                window.Ya.Context.AdvManager.render({
                  blockId: "R-A-1691059-66",
                  renderTo: "yandex_rtb_R-A-1691059-66"
                })
              })}</script>
          </div>

        </div>


        {/* <div className="video-section-main-page">
          <VideoSectionRandom limitForShow={24} />
        </div> */}

      </div>


    </>
  );
};

export default ArticlesLayout;

