import React, { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import './VisitCounter.css';

const VisitCounter = () => {
    const [visitCount, setVisitCount] = useState();
    const [bestCount, setBestCount] = useState(0);
    const [showCalendar, setShowCalendar] = useState(false);
    const [highlightedDates, setHighlightedDates] = useState([]);

    useEffect(() => {
        const today = new Date().toDateString();
        const lastVisit = localStorage.getItem('lastVisit');
        let dates = JSON.parse(localStorage.getItem('highlightedDates') || '[]');
        let bestStreak = parseInt(localStorage.getItem('bestCount') || '1', 10);

        if (lastVisit) {
            const lastVisitDate = new Date(lastVisit);
            const yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - 1);

            if (lastVisitDate.toDateString() === yesterday.toDateString()) {
                const newCount = parseInt(localStorage.getItem('visitCount') || '1', 10) + 1;
                setVisitCount(newCount);
                localStorage.setItem('visitCount', newCount.toString());
                dates.push(today);
                if (newCount > bestStreak) {
                    bestStreak = newCount;
                    localStorage.setItem('bestCount', bestStreak.toString());
                }
            } else if (lastVisitDate.toDateString() !== today) {
                setVisitCount(1);
                localStorage.setItem('visitCount', '1');
                dates = [today];
            }
            else if (lastVisitDate.toDateString() === today) {
                const newCount = parseInt(localStorage.getItem('visitCount') || '1', 10);
                const newBestCount = parseInt(localStorage.getItem('bestCount') || '1', 10);
                setVisitCount(newCount);

                if (newCount > newBestCount) {
                    bestStreak = newCount;
                    localStorage.setItem('bestCount', bestStreak.toString());
                }
            }
        } else {
            setVisitCount(1);
            localStorage.setItem('visitCount', '1');
            dates = [today];
            bestStreak = 1;
            localStorage.setItem('bestCount', '1');
        }

        setHighlightedDates(dates);
        setBestCount(bestStreak);
        localStorage.setItem('highlightedDates', JSON.stringify(dates));
        localStorage.setItem('lastVisit', today);
    }, []);

    const toggleCalendar = () => {
        setShowCalendar(!showCalendar);
    };

    const tileClassName = ({ date, view }) => {
        if (view === 'month') {
            let formattedDate = date.toDateString();
            if (highlightedDates.includes(formattedDate)) {
                return 'highlight-calendar';
            }
        }
    };

    const wordEnding = (number) => {
        // Handle negative numbers
        if (number < 0) return 'дней'; // or throw an error, based on your application's needs

        // Handle the special case of 0
        if (number === 0) return 'дней';

        // Russian numerals follow a pattern for numbers ending in 1, except for 11
        if (number % 10 === 1 && number % 100 !== 11) return 'день';

        // For numbers ending in 2, 3, 4 except 12, 13, 14
        if ([2, 3, 4].includes(number % 10) && ![12, 13, 14].includes(number % 100)) return 'дня';

        // For all other numbers
        return 'дней';
    };

    return (
        <div style={{ width: "50px" }}>
            <p onClick={toggleCalendar} style={{ color: 'white', cursor: 'pointer' }}>
                <i
                    style={{ color: 'white', marginRight: '5px' }}

                    className="fa fa-calendar-check"></i>
                {visitCount}
            </p>


            {showCalendar && (
                <>

                    <div className='calendar-overlay' onClick={toggleCalendar}></div>
                    <div className='calendar-container'>

                        <h3 style={{ textAlign: 'center' }}>Посещения</h3>
                        <p style={{ textAlign: 'center', padding: "0 5px" }}>Вы занимались: <b>{visitCount}</b> {wordEnding(visitCount)} подряд</p>
                        <p style={{ textAlign: 'center' }}>Лучшая серия: <b>{bestCount}</b> {wordEnding(bestCount)}</p>
                        <div className='close-calendar' onClick={toggleCalendar}>&times;</div>
                        <Calendar
                            tileClassName={tileClassName}
                        />
                    </div>
                </>
            )}
        </div>
    );
};

export default VisitCounter;
