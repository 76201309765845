import React, { useState, useEffect } from 'react';
import Markdown from 'markdown-to-jsx';
import TableOfContents from './TableOfContents';
import Loader from '../../shared/components/Loader';
import HelmetOneArticle from './HelmetOneArticle';

import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { getOneSectionArticleById } from '../../store/actions/articleActions';
import Articles from './Articles';


import './articles.css'

function OneArticle() {

    const dispatch = useDispatch();
    const article = useSelector(state => state.articleReducer.currentArticle);
    const { loading, error } = useSelector(state => state.articleReducer);

    const { urlName } = useParams();
    const { section } = useParams();

    useEffect(() => {
        if (!article) {
            dispatch(getOneSectionArticleById(section, urlName));
        }
    }, [dispatch, article, urlName, section]);


    const [headings, setHeadings] = useState([]);

    useEffect(() => {
        function replaceWordsWithHyphens(inputString) {
            if (typeof inputString !== 'undefined' && inputString !== null) {
                const numberOfWords = inputString.split(' ').length;
                const strArr = inputString.split(' ');
                for (let i = 0; i < numberOfWords; i++) {
                    strArr[i] = strArr[i].replace(/[^a-zA-Z0-9]/g, '');
                }
                inputString = strArr.join('-');
            }
            return inputString;
        }

        function validateHeading(heading) {
            // Регулярное выражение для поиска формата [text](#link)
            const linkRegex = /\[([^\]]+)\]\(#([^)]+)\)/;

            // Проверка, соответствует ли заголовок формату
            const match = heading.match(linkRegex);

            // Если есть совпадение, возвращаем текст, иначе возвращаем исходный заголовок
            return match ? match[1] : heading;
        }

        if (!article) return;



        const headings = [];
        /* const headingRegex = /^#{1,6}\s(.*)/gm; */
        const headingRegex = /^#{1,2}\s(.*)/gm;
        let match = headingRegex.exec(article.markdown);
        while (match !== null) {
            replaceWordsWithHyphens(match[1].toLowerCase())
            headings.push({
                id: replaceWordsWithHyphens(match[1].toLowerCase()),
                value: validateHeading(match[1]),
            });
            match = headingRegex.exec(article.markdown);
        }

        setHeadings(headings);
    }, [article]);



    const options = {
        overrides: {
            h1: {
                component: TableOfContents,
                props: {
                    headings: headings && headings,
                    article: article && article,
                    author: (article && article.author) || "",
                    date: article && article.date,
                },
            },
            img: {
                component: 'img',
                props: {
                    style: {
                        maxWidth: '100%',
                    },
                },
            },
        },
    };

    return (
        <>
            {article && <HelmetOneArticle
                article={article}
                urlName={urlName}
                section={section}
                canonicalUrl={`https://pddportal.ru/articles/${section}/${urlName}/`}
                tableOfContents={headings}
            />}
            {article && (<>
                <div className='shared-card' style={{ marginLeft: 0, marginRight: 0 }}>
                    <div className='article-container'>
                        {headings && <Markdown options={options}>{article.markdown}</Markdown>}
                    </div>
                </div>

                <Articles hideFromOffset={article.hideFromOffset} />


            </>)
            }

            {
                <div>
                    {/* Десктопный полноразмерный */}
                    <div id="R-A-1691059-68"></div>
                    <script>
                        {window.yaContextCb.push(() => {
                            window.Ya.Context.AdvManager.render({
                                blockId: "R-A-1691059-68",
                                type: "fullscreen",
                                platform: "desktop"
                            })
                        })}
                    </script>
                </div>
            }

            {
                <div>
                    {/* Мобильный полноразмерный */}
                    <div id="R-A-1691059-67"></div>
                    <script>
                        {window.yaContextCb.push(() => {
                            window.Ya.Context.AdvManager.render({
                                blockId: "R-A-1691059-67",
                                type: "fullscreen",
                                platform: "touch"
                            })
                        })}
                    </script>
                </div>
            }


            {loading && <Loader
                type="Oval"
                color="#00BFFF"
                height={90}
                width={90}
                style={{ margin: "30px auto", display: "block", textAlign: "center" }}
            />}
            {error && <h3>{error}</h3>}

        </>
    );
}

export default OneArticle;