import React, { useState } from "react";
import "./MobileButtons.css";

function MobileButtons(props) {
  const [iosOpen, setIosOpen] = useState(false);
  const [androidOpen, setAndroidOpen] = useState(false);

  const clickHandler = (name) => {
    switch (name) {
      case "ios":
        setIosOpen(!iosOpen);
        setAndroidOpen(false)
        break;
      case "android":
        setAndroidOpen(!androidOpen);
        setIosOpen(false)
        break;

      default:
        break;
    }
  };

  return (
    <div style={{ marginTop: "1rem" }}>
      <div
        onClick={() => clickHandler("ios")}
        className="button no-mobile-hover button-ios-android"
        style={{
          margin: "0 auto 1.1rem auto",
          width: "auto",
        }}
      >
        <i className="fab fa-apple app-mini-icon-margin"></i>
        ПДД для iOS
      </div>
      {iosOpen && (
        <>
          <p className="instruction-description">
            Сайт имеет функциональность приложения. Добавьте его на свой
            домашний экран телефона, чтобы использовать его в полноэкранном
            режиме.
          </p>
          <hr />

          <div className="instruction">
            <img
              src="images/shareios.png"
              alt="share Ios button"
              className="w25"
            />
            <p className="instruction-text"> 1. Нажмите кнопку "Поделиться"</p>
          </div>
          <div className="instruction">
            <i className="far fa-plus-square w25"></i>
            <p className="instruction-text">
              2. Нажмите "Добавить на экран домой"
            </p>
          </div>
        </>
      )}
      <div
        onClick={() => clickHandler("android")}
        className="button no-mobile-hover button-ios-android"
        style={{
          margin: "0 auto 1.1rem auto",
          width: "auto",
        }}
      >
        <i className="fab fa-android app-mini-icon-margin android-color"></i>
        ПДД для Android
      </div>
      {androidOpen && (
        <>
          <p className="instruction-description">
            Сайт имеет функциональность приложения. Добавьте его на свой
            домашний экран телефона, чтобы использовать его в полноэкранном
            режиме.
          </p>
          <hr />
          <p className="instruction-description"> Откройте меню Chrome и выберите «Добавить на главный экран»</p>
        </>
      )}
    </div>
  );
}

export default MobileButtons;
