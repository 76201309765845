
import {
    SET_CURRENT_VIDEO,
    GET_SECTION_VIDEOS_REQUEST,
    GET_SECTION_VIDEOS_SUCCESS,
    GET_SECTION_VIDEOS_FAIL,
    CLEAR_SECTION_VIDEOS,
    ADD_OFFSET_TO_SECTION_VIDEOS,
    GET_SECTION_VIDEO_FAIL,
    GET_SECTION_VIDEO_REQUEST,
    GET_SECTION_VIDEO_SUCCESS,
    GET_RANDOM_VIDEOS_REQUEST,
    GET_RANDOM_VIDEOS_SUCCESS,
    GET_RANDOM_VIDEOS_FAIL,
    CLEAR_RANDOM_VIDEOS,
    CLEAR_HALF_RANDOM_VIDEOS
} from './constants/videoConstants';


const initialVidiosState = {
    videos: [],
    hasMore: true,
    loading: false,
    error: null,
    offset: 0,
    sectionName: '',
    cancelToken: null
};

const initialRandomVidiosState = {
    videos: [],
    hasMore: true,
    loading: false,
    error: null,
    offset: 0,
    sectionName: ''
};

export const videoReducer = (state = {}, action) => {
    const { type, payload } = action;

    switch (type) {
        case SET_CURRENT_VIDEO:
            return {
                ...state,
                currentVideo: payload,
            };
        case GET_SECTION_VIDEO_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_SECTION_VIDEO_SUCCESS:
            return {
                ...state,
                loading: false,
                currentVideo: payload,
                error: null
            };
        case GET_SECTION_VIDEO_FAIL:
            return {
                ...state,
                loading: false,
                error: payload,
            };
        default:
            return state;
    }
}


export const sectionVideosReducer = (state = initialVidiosState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_SECTION_VIDEOS_REQUEST:
            return {
                ...state,
                loading: true,
                cancelToken: payload.cancelToken ? payload.cancelToken : null
            };
        case GET_SECTION_VIDEOS_SUCCESS:
            return {
                ...state,
                loading: false,
                videos: [...state.videos, ...payload.videos],
                hasMore: payload.hasMore,
                sectionName: payload.sectionName,
                error: null,
            };
        case GET_SECTION_VIDEOS_FAIL:
            return {
                ...state,
                loading: false,
                error: payload,
            };
        case CLEAR_SECTION_VIDEOS:
            return initialVidiosState;
        case ADD_OFFSET_TO_SECTION_VIDEOS:
            return {
                ...state,
                offset: payload
            }
        default:
            return state;
    }
}

export const randomVideosReducer = (state = initialRandomVidiosState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_RANDOM_VIDEOS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_RANDOM_VIDEOS_SUCCESS:
            return {
                ...state,
                loading: false,
                videos: [...state.videos, ...payload.videos],
                hasMore: payload.hasMore,
                error: null,

            };
        case GET_RANDOM_VIDEOS_FAIL:
            return {
                ...state,
                loading: false,
                error: payload,
            };
        case CLEAR_RANDOM_VIDEOS:
            return initialRandomVidiosState;
        case CLEAR_HALF_RANDOM_VIDEOS:
            const halfLength = Math.floor(state.videos.length / 2);
            return {
                ...state,
                videos: state.videos.slice(0, halfLength)
            }
        default:
            return state;
    }
}