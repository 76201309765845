import React from 'react';
import ArticlesLayout from './ArticlesLayout';
import Articles from './components/Articles';
import Helmet from 'react-helmet';

function ArticlesPage(props) {
    return (
        <ArticlesLayout>
            <Helmet>
                <title>Статьи на автомобильную тему | PDDPORTAL.RU</title>
                <meta name="description" content="Исследуйте захватывающие автомобильные статьи на нашем сайте! От увлекательных тест-драйвов до последних новостей автомобильной индустрии. Узнайте о технологических инновациях, безопасности на дорогах и трендах в мире автомобилей. Прочтите экспертные мнения и полезные советы для автолюбителей. Ваш источник увлекательной и информативной автомобильной информации! " />
                <meta name="keywords" content="Статьи | PDDPORTAL.RU" />
                <meta name="author" content="PDDPORTAL.RU" />
                <meta name="language" content="RU" />
                <link rel="canonical" href="https://pddportal.ru/articles/other/" />
            </Helmet>
            <Articles />
        </ArticlesLayout>
    );
}

export default ArticlesPage;




