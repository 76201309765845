import React from 'react';
import { Helmet } from 'react-helmet';
import { createDateFromTimestamp } from '../../shared/functions/utilities/createDateFromTimestamp';

const HelmetOneArticle = ({ article, section, canonicalUrl, twitterHandle, tableOfContents }) => {
    const {
        title,
        description,
        author,
        date,
        image,
        markdown
    } = article;

    const formattedDate = createDateFromTimestamp(date);
    const READING_SPEED = 170; //words per minute

    const countArticleWords = (articleMarkdown) => {
        const words = articleMarkdown.split(' ');
        return words.length;
    }

    const countArticleReadingTime = (articleMarkdown) => {
        const words = countArticleWords(articleMarkdown);
        const minutes = Math.ceil(words / READING_SPEED);
        return minutes;
    }

    const readingTime = countArticleReadingTime(markdown);

    return (
        <Helmet>
            {/* Document metadata */}
            <title>{`${title} | PDDPORTAL.RU`}</title>
            <meta name="description" content={description} />
            <meta name="keywords" content={title} />
            <meta name="author" content={author.name} />
            <meta name="language" content="RU" />
            <link rel="canonical" href={canonicalUrl || window.location.href} />

            {/* Open Graph meta tags for social media sharing */}
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:type" content="article" />
            <meta property="og:url" content={canonicalUrl || window.location.href} />
            <meta property="og:image" content={image || "/images/icons/logo192.png"} />
            <meta property="og:article:published_time" content={formattedDate} />
            <meta property="og:article:author" content={author.name} />
            <meta property="og:article:section" content={section} />
            <meta property="og:article:modified_time" content={formattedDate} />

            {/* Twitter card meta tags */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={image || "/images/icons/logo192.png"} />
            {twitterHandle && <meta name="twitter:creator" content={`@${twitterHandle}`} />}

            {/* Schema.org markup for Google Rich Cards */}
            <script type="application/ld+json">
                {JSON.stringify({
                    "@context": "http://schema.org",
                    "@type": "Article",
                    headline: title,
                    description,
                    keywords: title,
                    author: {
                        "@type": "Person",
                        name: author.name,
                    },
                    datePublished: formattedDate,
                    dateModified: formattedDate,
                    image: image || "/images/icons/logo192.png",
                    publisher: {
                        "@type": "Organization",
                        name: "PDDPORTAL.RU",
                        logo: {
                            "@type": "ImageObject",
                            url: "/images/icons/logo192.png",
                        },
                    },
                    mainEntityOfPage: {
                        "@type": "WebPage",
                        "@id": canonicalUrl || window.location.href,
                    },
                    articleSection: section,
                    timeRequired: `PT${readingTime}M`, // Assuming readingTime is in minutes
                    hasPart: {
                        "@type": "TableOfContents",
                        name: "Оглавление",
                        description: "Оглавление статьи",
                        isAccessibleForFree: "True",
                        cssSelector: "toc",
                        about: {
                            "@type": "ItemList",
                            itemListElement: tableOfContents.map((item, index) => ({
                                "@type": "ListItem",
                                position: index + 1,
                                item: {
                                    "@id": item.id,
                                    name: item.value,
                                },
                            })),
                        },
                    }
                })}
            </script>
        </Helmet>
    );
};

export default HelmetOneArticle;
