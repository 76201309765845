import React from 'react';
import ArticlesLayout from './ArticlesLayout';
import OneArticle from './components/OneArticle';

function OneArticlePage(props) {
    return (
        <ArticlesLayout>
            <OneArticle />
        </ArticlesLayout>
    );
}

export default OneArticlePage;