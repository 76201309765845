import React, { useEffect } from 'react';
import { VideoPreviewMaxi } from './components/VideoPreview';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getRandomVideos } from '../store/actions/videoActions';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from '../shared/components/Loader';
import GridVideoSkeletonLoader from '../shared/components/GridVideoSkeletonLoader';
import {SPECIAL_INDEXIES as specialIndexies}  from '../constants/otherConstants'

import adsObjVideoSectionRandom from './components/AdVideoSectionRandom';

import './VideoSection.css';
import './components/video.css';


function VideoSectionRandom({ limitForShow }) {
    const dispatch = useDispatch();
    const history = useHistory();
    const sectionName = history.location.pathname.split('/')[1];

    const { loading, videos, error, hasMore } = useSelector((state) => state.randomVideosReducer);
    let displayedVideos;

    if (limitForShow && limitForShow < videos.length) {
        displayedVideos = videos.slice(0, limitForShow);
    }

    useEffect(() => {
        dispatch(getRandomVideos());
    }, [dispatch, sectionName]);

    const fetchMoreVideos = () => {
        dispatch(getRandomVideos());
    };

    return (
        <>
            {loading && videos.length === 0 && <GridVideoSkeletonLoader />}
            {
                limitForShow && <div className='video-section'>
                    {displayedVideos && displayedVideos.map((video, index) => {
                        if (specialIndexies.includes(index)) {
                            return (
                                <React.Fragment key={video.id.videoId}>
                                    <div className='special-preview-block video-preview-maxi'>
                                        {adsObjVideoSectionRandom[index]}
                                    </div>
                                    <VideoPreviewMaxi
                                        thumbnailUrl={video.snippet.thumbnails.medium.url}
                                        title={video.snippet.title}
                                        channelName={video.snippet.channelTitle}
                                        publishedAt={video.snippet.publishTime}
                                        videoId={video.id.videoId}
                                        description={video.snippet.description}
                                        sectionName={video.sectionName}
                                    />
                                </React.Fragment>
                            );
                        } else return <VideoPreviewMaxi
                            key={video.id.videoId}
                            thumbnailUrl={video.snippet.thumbnails.medium.url}
                            title={video.snippet.title}
                            channelName={video.snippet.channelTitle}
                            publishedAt={video.snippet.publishTime}
                            videoId={video.id.videoId}
                            description={video.snippet.description}
                            sectionName={video.sectionName}
                        />
                    }
                    )}
                </div>
            }
            {
                !limitForShow && <InfiniteScroll
                    dataLength={(videos.length) || 0} // This is important
                    next={fetchMoreVideos}
                    hasMore={hasMore}
                    children={videos}
                    loader={<Loader
                        type="Oval"
                        color="#00BFFF"
                        height={90}
                        width={90}
                        style={{ margin: "30px auto", display: "block", textAlign: "center" }}
                    />}
                    endMessage={<p>No more videos to load</p>}
                >
                    <div className='video-section'>
                        {videos && videos.map((video, index) => {
                            if (specialIndexies.includes(index)) {
                                return (
                                    <React.Fragment key={video.id.videoId}>
                                        <div className='special-preview-block video-preview-maxi'>
                                            {adsObjVideoSectionRandom[index]}
                                        </div>
                                        <VideoPreviewMaxi
                                            thumbnailUrl={video.snippet.thumbnails.medium.url}
                                            title={video.snippet.title}
                                            channelName={video.snippet.channelTitle}
                                            publishedAt={video.snippet.publishTime}
                                            videoId={video.id.videoId}
                                            description={video.snippet.description}
                                            sectionName={video.sectionName}
                                            videosArrLength={videos.length}
                                        />
                                    </React.Fragment>
                                );
                            } else return <VideoPreviewMaxi
                                key={video.id.videoId}
                                thumbnailUrl={video.snippet.thumbnails.medium.url}
                                title={video.snippet.title}
                                channelName={video.snippet.channelTitle}
                                publishedAt={video.snippet.publishTime}
                                videoId={video.id.videoId}
                                description={video.snippet.description}
                                sectionName={video.sectionName}
                                videosArrLength={videos.length}
                            />
                        }
                        )}
                    </div>
                </InfiniteScroll>
            }
            {error && <div>{error}</div>}
        </>
    );
}

export default VideoSectionRandom;