import React from 'react';

function ReadingTimeComponent({ article }) {

    const READING_SPEED = 170; //words per minute

    const countArticleWords = (article) => {
        const words = article.split(' ');
        return words.length;
    }

    const countArticleReadingTime = (article) => {
        const words = countArticleWords(article);
        const minutes = Math.ceil(words / READING_SPEED);
        return minutes;
    }

    const wordEnding = (minutes) => {
        let ending = '';
        if (minutes === 1) {
            ending = 'а';
        } else if (minutes > 1 && minutes < 5) {
            ending = 'ы';
        } else {
            ending = '';
        }
        return ending;
    }

    const getReadingTime = (article) => {
        const minutes = countArticleReadingTime(article);
        const ending = wordEnding(minutes);
        return `${minutes} минут${ending}`;
    }

    return (<span><i className="fas fa-clock"></i> Время чтения: {getReadingTime(article)}</span>);
}

export default ReadingTimeComponent;