import React from 'react';
import { NavLink } from 'react-router-dom'

import TicketsSideBarMobileChecker from '../tickets/components/Sidebar/TicketsSideBarMobileChecker';

import './VideoSection.css';

function VideoSectionSkeleton(props) {

   const onLinkClick = (params) => {
        window.scrollTo(0, 0);
        if (params) {
            params.closeDrawerHandler();
        }
    }

    return (
        <>
            <TicketsSideBarMobileChecker >
                {
                    (params) =>
                        <nav className='video-section-nav'>
                            <div style={{ marginTop: "3.35rem" }}></div>
                            <section>
                                <NavLink to="/videos/" activeClassName="video-section-nav-item-activ" className='video-section-nav-item' onClick={()=>onLinkClick(params)}>
                                    Подборка
                                </NavLink>
                                <NavLink to="/category/driving-school/" activeClassName="video-section-nav-item-activ" className='video-section-nav-item' onClick={()=>onLinkClick(params)}>
                                    Автошкола
                                </NavLink>
                                <NavLink to="/category/dtp-razbor/" activeClassName="video-section-nav-item-activ" className='video-section-nav-item' onClick={()=>onLinkClick(params)}>
                                    Разбор ДТП
                                </NavLink>

                                <NavLink to="/category/car-info/" activeClassName="video-section-nav-item-activ" className='video-section-nav-item' onClick={()=>onLinkClick(params)}>
                                    Устройство <br /> автомобиля
                                </NavLink>

                                <NavLink to="/category/auto-review/" activeClassName="video-section-nav-item-activ" className='video-section-nav-item' onClick={()=>onLinkClick(params)}>
                                    Автообзоры
                                </NavLink>

                                <NavLink to="/category/dtp/" activeClassName="video-section-nav-item-activ" className='video-section-nav-item' onClick={()=>onLinkClick(params)}>
                                    Аварии
                                </NavLink>

                                <NavLink to="/category/law/" activeClassName="video-section-nav-item-activ" className='video-section-nav-item' onClick={()=>onLinkClick(params)}>
                                    Юридические <br /> вопросы
                                </NavLink>

                                <NavLink to="/category/show/" activeClassName="video-section-nav-item-activ" className='video-section-nav-item' onClick={()=>onLinkClick(params)}>
                                    Шоу
                                </NavLink>
                                <NavLink to="/category/bloggers/" activeClassName="video-section-nav-item-activ" className='video-section-nav-item' onClick={()=>onLinkClick(params)}>
                                    Автоблогеры
                                </NavLink>
                                <NavLink to="/category/other/" activeClassName="video-section-nav-item-activ" className='video-section-nav-item' onClick={()=>onLinkClick(params)}>
                                    Другое
                                </NavLink>
                             {/*    <NavLink to="/category/pursuit" activeClassName="video-section-nav-item-activ" className='video-section-nav-item' onClick={()=>onLinkClick(params)}>
                                    Погони
                                </NavLink> */}

                            </section>

                            {/* <Link to='/'>
                                <h6 className='video-section-nav-item video-section-exit'>Выход</h6>
                            </Link> */}
                        </nav>
                }
            </TicketsSideBarMobileChecker>

            <div className='video-section-main'>
                {props.children}
            </div>
        </>
    );
}

export default VideoSectionSkeleton;

/* style={{ border: "1px solid black", height: "500px" }} */