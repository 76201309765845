import React, { useState } from "react";
import { Link } from "react-router-dom";

import { connect } from "react-redux";
import MainHeader from "./MainHeader";
import SideDrawer from "./SideDrawer";
import Backdrop from "../UIElements/Backdrop";
import "./ContextMenu.css";
import Timer from "../../tickets/components/Timer";
import useWindowDimensions from "../../WindowDimensions-hook/useWindowDimensions";
import VisitCounter from "../components/VisitCounter/VisitCounter";


import * as actionTypes from "../../store/actions";

const ContextMenu = (props) => {
  const year = new Date().getFullYear();
  const mobileWidth = 600;
  const { width } = useWindowDimensions();
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const openDrawerHandler = () => {
    setDrawerIsOpen(true);
  };

  const closeDrawerHandler = () => {
    setDrawerIsOpen(false);
  };

  const onExitClick = () => {
    if (props.categoryTickets === "errorsAB" || props.categoryTickets === "errorsCD") {
      let errorsArray = JSON.parse(localStorage.getItem("errorsArray"));
      if (errorsArray !== null) {
        errorsArray = errorsArray.filter((el) => el.ticketErrors.length !== 0);
        if (errorsArray.length === 0) localStorage.removeItem("errorsArray");
        else {
          localStorage.setItem("errorsArray", JSON.stringify(errorsArray));
        }
      }
    }
    props.resetToInitialState();
    window.scrollTo(0, 0);
  };

  const conditionTimer = () => {
    if (props.categoryTickets === "ticketsAB" || props.categoryTickets === "ticketsCD" || props.categoryTickets === "examAB" || props.categoryTickets === "examCD") {

      if (props.loadedBilet.tasks.length !== props.numbersStateArray.length) {
        return <Timer timerColor="white" />
      }

    }
    else return
  }

  return (
    <React.Fragment>
      {drawerIsOpen && <Backdrop onClick={closeDrawerHandler} />}
      <SideDrawer show={drawerIsOpen}>
        <nav className="main-navigation__drawer-nav">
          {props.children({
            closeDrawerHandler,
          })}
        </nav>
      </SideDrawer>
      <MainHeader>


        {(width <= mobileWidth &&
          props.categoryTickets && props.categoryTickets !== "videos"
          && props.categoryTickets !== "category"
          && props.categoryTickets !== "articles")
          && <Link onClick={onExitClick} className="easy-pointer" to={"/"}>
            <i style={{ fontSize: "1.35rem", color: "white" }} className="fas fa-chevron-left" title="Вернуться на Главную"></i>
          </Link>}





        {(width <= mobileWidth && props.categoryTickets && (props.categoryTickets === "videos" || props.categoryTickets === "category" || props.categoryTickets === "articles")) &&
          <div className="flex-container">
            <Link onClick={onExitClick} to="/" className="main-navigation__title main-navigation-link">
              <img src='/images/icons/trafficlight.png' alt='логотип сайта в виде светофора' className="main-navigation__title mr-05 main-navigation-logo" />
            </Link>

            {width <= mobileWidth
              && <Link onClick={onExitClick} to="/" className="main-navigation__title main-navigation-link">
                <i className="fa fa-home"></i>
              </Link>}

            {width > mobileWidth
              && <Link onClick={onExitClick} to="/" className="main-navigation__title main-navigation-link"> Главная </Link>}
            <Link onClick={onExitClick} to="/videos/" className="main-navigation__title main-navigation-link ml-1"> Видео </Link>
            <Link onClick={onExitClick} to="/articles/other/" className="main-navigation__title main-navigation-link ml-1"> Статьи </Link>

            <div style={{ marginLeft: "15px" }}>
              <VisitCounter />
            </div>


          </div>
        }


        <div className={props.categoryTickets === "videos" || props.categoryTickets === "category" ? "video-header-content" : "main-header-content"}>
          {(width > mobileWidth || !props.categoryTickets) &&
            <div className="flex-container">
              <Link onClick={onExitClick} to="/" className="main-navigation__title main-navigation-link">
                <img src='/images/icons/trafficlight.png' alt='логотип сайта в виде светофора' className="main-navigation__title mr-05 main-navigation-logo" />
              </Link>
              <h2 className="main-navigation__title mr-1">
                <Link
                  onClick={onExitClick}
                  to="/"
                  id="main-navigation-date-link">
                  ПДД {year}
                </Link>
              </h2>
              {/* <Link
                onClick={onExitClick}
                to="/"
                className="main-navigation__title main-navigation-link mr-1"
                id="main-navigation-home-link">
                Главная
              </Link> */}
              <Link
                onClick={onExitClick}
                to="/videos/"
                className="main-navigation__title main-navigation-link mr-1"
                id="main-navigation-videos-link">
                Видео
              </Link>
              <Link
                onClick={onExitClick}
                to="/articles/other/"
                className="main-navigation__title main-navigation-link mr-1"
                id="main-navigation-articles-link">
                Статьи </Link>
              <VisitCounter />
            </div>}



          <h2 className="main-navigation__title flex-container" style={{ lineHeight: "0.85" }}>
          
            {props.he}
            {props.he && <div style={{marginLeft:"10px"}} ><VisitCounter /></div>  }
          </h2>

        

          <div className="flex-container">

            {width <= mobileWidth && conditionTimer()}

            {width <= mobileWidth &&
              (props.categoryTickets === "ticketsAB" || props.categoryTickets === "ticketsCD") && <span className="categories-context-menu-info">{props.categoryTickets.slice(7)}</span>
            }

            {!props.notRenderBurgerMenuIcon && (
              <button
                title="Кнопка контекстного меню"
                className="main-navigation__menu-btn"
                onClick={openDrawerHandler}
              >
                <span />
                <span />
                <span />
              </button>
            )}

          </div>
          <nav className="main-navigation__header-nav" style={{ paddingLeft: "1rem" }}>
            <p style={{ color: "white" }}>Официальные билеты ПДД {year}</p>
          </nav>
        </div>

      </MainHeader>
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetToInitialState: () =>
      dispatch({
        type: actionTypes.RESET_TO_INITIAL_STATE,
      }),
  };
};

const mapStateToProps = (state) => {
  return {
    categoryTickets: state.router.location.pathname.split("/")[1],
    numbersStateArray: state.ticketsReducer.numbersStateArray,
    loadedBilet: state.ticketsReducer.loadedBilet,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContextMenu);
