import React from "react";
import achivmentsHandler from "../../main/components/Achivments/achivmentsHandler";
import { connect } from "react-redux";
import { setAlert } from "../../store/actions/alert";
import achivmentsAlertHandler from "./Alert/achivmentsAlertHandler";

function VkGroupClick(props) {
  const onVkGroupClick = (e) => {
    e.preventDefault();
    const arrAchivmentComplete = achivmentsHandler(["friend"]);
    achivmentsAlertHandler(arrAchivmentComplete, props.setAlert);
    if (arrAchivmentComplete.length > 0) {
      setTimeout(
        () => window.open("https://vk.com/pdd_portal", "_blank"),
        900
      );
    } else window.open("https://vk.com/pdd_portal", "_blank");
  };
  
  return <div onClick={onVkGroupClick}>{props.children}</div>;
}

const mapDispatchToProp = (dispatch) => {
  return { setAlert: setAlert(dispatch) };
};

export default connect(null, mapDispatchToProp)(VkGroupClick);
