import React, { useEffect } from "react";
import Loader from "./shared/components/Loader";
import loadable from "@loadable/component";
import { Route, Redirect, Switch } from "react-router-dom";
import smoothscroll from "smoothscroll-polyfill";

import "./tickets/components/Sidebar/TicketsSideBar.css";
import "./tickets/components/Sidebar/TicketSideBarItem.css";
import "./tickets/components/TicketBody.css";
import "./tickets/components/TicketHeader.css";
import "./App.css";
import "./video-section/VideoSection.css";
import "./video-section/components/video.css";
import "./shared/Navigation/ContextMenu.css";
import "./shared/Navigation/MainHeader.css";

import NotFound from "./errorPages/NotFound";
import Alerts from "./shared/components/Alert/Alerts";

import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import * as actionTypes from "./store/actions";

import HelmetVideoSection from "./video-section/HelmetVideoSection";
import VideoSectionSkeleton from "./video-section/VideoSectionSkeleton";
import VideoSection from "./video-section/VideoSection";
import VideoSectionRandom from "./video-section/VideoSectionRandom";
import OneVideo from "./video-section/components/OneVideo";
/* import ContextMenu from "./shared/Navigation/ContextMenu"; */

import OneArticlePage from "./article-section/OneArticlePage";
import ArticlesPage from "./article-section/ArticlesPage";

import Footer from "./shared/components/Footer/Footer";

const Main = loadable(() => import("./main/pages/Main"), {
  delay: 100,
  fallback: <Loader center={true} />,
});
const TrainingMode = loadable(() => import("./tickets/pages/TrainingMode"), {
  delay: 100,
  fallback: <Loader center={true} />,
});
const ThemeMode = loadable(() => import("./tickets/pages/ThemeMode"), {
  delay: 100,
  fallback: <Loader center={true} />,
});
const NewQuestionsMode = loadable(() => import("./tickets/pages/NewQuestionsMode"), {
  delay: 100,
  fallback: <Loader center={true} />,
});

const ErrorMode = loadable(() => import("./tickets/pages/ErrorMode"), {
  delay: 100,
  fallback: <Loader center={true} />,
});
const ExamMode = loadable(() => import("./tickets/pages/ExamMode"), {
  delay: 100,
  fallback: <Loader center={true} />,
});
const RulesMode = loadable(() => import("./rules/pages/RulesMode"), {
  delay: 100,
  fallback: <Loader center={true} />,
});


const App = (props) => {
  smoothscroll.polyfill();
  const history = useHistory();

  useEffect(() => {
    return history.listen((location) => {
      if (history.location.pathname === "/") {
        props.resetToInitialState();
      }
      // Действия при нажатой кнопке назад браузера
      if (history.action === "POP") {
        const routeNames = ["rules", "signs", "marks", "access", "/", "category", "videos", "articles"];
        if (
          routeNames.includes(history.location.pathname.split("/")[1]) ||
          history.location.pathname === "/"
        ) {
        } else {
          /* console.log(history.location.pathname, "Назад по кнопке из браузера"); */
          history.push("/");
        }
      }

      window.ym(70848268, "hit", history.location.pathname);
      window.gtag("send", "pageview", history.location.pathname);
    });

  }, [history, props]);

  return (
    <main>
      <Alerts />
      <div className="site">
        <Switch>
          <Route path="/videos" exact>
            <HelmetVideoSection>
              <VideoSectionSkeleton>
                <VideoSectionRandom />
              </VideoSectionSkeleton>
            </HelmetVideoSection>
          </Route>
          <Route path="/category/:category/" exact>
            <HelmetVideoSection>
              <VideoSectionSkeleton>
                <VideoSection />
              </VideoSectionSkeleton>
            </HelmetVideoSection>
          </Route>
          <Route path="/category/:category/:videoId" exact>
            <VideoSectionSkeleton>
              <OneVideo />
            </VideoSectionSkeleton>
          </Route>



          {/* <Route path="/articles" exact>
            <ArticlesRandom />
          </Route> */}
          <Route path="/articles/:section" exact>
            <ArticlesPage />
          </Route>
          <Route path="/articles/:section/:urlName" exact>
            <OneArticlePage />
          </Route>

          <Route path="/" exact>
            <Main />
          </Route>
          <Route path="/ticketsAB/:biletId/:taskId" exact>
            <TrainingMode />
          </Route>
          <Route path="/ticketsCD/:biletId/:taskId" exact>
            <TrainingMode />
          </Route>
          <Route path="/themeAB/:biletId/:taskId" exact>
            <ThemeMode />
          </Route>
          <Route path="/themeCD/:biletId/:taskId" exact>
            <ThemeMode />
          </Route>
          <Route path="/newAB/:taskId" exact>
            <NewQuestionsMode />
          </Route>
          <Route path="/newCD/:taskId" exact>
            <NewQuestionsMode />
          </Route>
          <Route path="/errorsAB/:biletId/:taskId" exact>
            <ErrorMode />
          </Route>
          <Route path="/errorsCD/:biletId/:taskId" exact>
            <ErrorMode />
          </Route>
          <Route path="/examAB/:taskId" exact>
            <ExamMode />
          </Route>
          <Route path="/examCD/:taskId" exact>
            <ExamMode />
          </Route>
          <Route path="/rules/:ruleId" exact>
            <RulesMode />
          </Route>
          <Route path="/signs/:ruleId" exact>
            <RulesMode />
          </Route>
          <Route path="/marks/:ruleId" exact>
            <RulesMode />
          </Route>
          <Route path="/access/:ruleId" exact>
            <RulesMode />
          </Route>
          <Route status={404}>
            <NotFound />
          </Route>
          <Redirect to="/" />
        </Switch>
      </div>
      <Footer />

    </main>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetToInitialState: () =>
      dispatch({
        type: actionTypes.RESET_TO_INITIAL_STATE,
      }),
  };
};

export default connect(null, mapDispatchToProps)(App);
