
import {
    SET_CURRENT_ARTICLE,
    GET_SECTION_ARTICLES_REQUEST,
    GET_SECTION_ARTICLES_SUCCESS,
    GET_SECTION_ARTICLES_FAIL,
    CLEAR_SECTION_ARTICLES,
    ADD_OFFSET_TO_SECTION_ARTICLES,
    GET_SECTION_ARTICLE_FAIL,
    GET_SECTION_ARTICLE_REQUEST,
    GET_SECTION_ARTICLE_SUCCESS,
    GET_RANDOM_ARTICLES_REQUEST,
    GET_RANDOM_ARTICLES_SUCCESS,
    GET_RANDOM_ARTICLES_FAIL,
    CLEAR_RANDOM_ARTICLES,
    CLEAR_HALF_RANDOM_ARTICLES
} from './constants/articleConstants';


const initialArticlesState = {
    articles: [],
    hasMore: true,
    loading: false,
    error: null,
    offset: 0,
    sectionName: '',
    cancelToken: null
};

const initialRandomArticlesState = {
    articles: [],
    hasMore: true,
    loading: false,
    error: null,
    offset: 0,
    sectionName: ''
};

export const articleReducer = (state = {}, action) => {
    const { type, payload } = action;

    switch (type) {
        case SET_CURRENT_ARTICLE:
            return {
                ...state,
                currentArticle: payload,
            };
        case GET_SECTION_ARTICLE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_SECTION_ARTICLE_SUCCESS:
            return {
                ...state,
                loading: false,
                currentArticle: payload,
                error: null
            };
        case GET_SECTION_ARTICLE_FAIL:
            return {
                ...state,
                loading: false,
                error: payload,
            };
        default:
            return state;
    }
}

export const sectionArticlesReducer = (state = initialArticlesState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_SECTION_ARTICLES_REQUEST:
            return {
                ...state,
                loading: true,
                cancelToken: payload.cancelToken ? payload.cancelToken : null
            };
        case GET_SECTION_ARTICLES_SUCCESS:
            return {
                ...state,
                loading: false,
                articles: [...state.articles, ...payload.articles],
                hasMore: payload.hasMore,
                sectionName: payload.sectionName,
                error: null,
            };
        case GET_SECTION_ARTICLES_FAIL:
            return {
                ...state,
                loading: false,
                error: payload,
            };
        case CLEAR_SECTION_ARTICLES:
            return initialArticlesState;
        case ADD_OFFSET_TO_SECTION_ARTICLES:
            return {
                ...state,
                offset: payload
            }
        default:
            return state;
    }
}

export const randomArticlesReducer = (state = initialRandomArticlesState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_RANDOM_ARTICLES_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_RANDOM_ARTICLES_SUCCESS:
            return {
                ...state,
                loading: false,
                articles: [...state.articles, ...payload.articles],
                hasMore: payload.hasMore,
                error: null,

            };
        case GET_RANDOM_ARTICLES_FAIL:
            return {
                ...state,
                loading: false,
                error: payload,
            };
        case CLEAR_RANDOM_ARTICLES:
            return initialRandomArticlesState;
        case CLEAR_HALF_RANDOM_ARTICLES:
            const halfLength = Math.floor(state.articles.length / 2);
            return {
                ...state,
                articles: state.articles.slice(0, halfLength)
            }
        default:
            return state;
    }
}