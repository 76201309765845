import React from "react";

const Idx1VideoSection = () => {
    return (
        <React.Fragment>
            <div id="yandex_rtb_R-A-1691059-13"></div>
            <script>
                {window.yaContextCb.push(() => {
                    window.Ya.Context.AdvManager.render({
                        blockId: "R-A-1691059-13",
                        renderTo: "yandex_rtb_R-A-1691059-13"
                    })
                })}</script>
        </React.Fragment>
    )
}

const Idx2VideoSection = () => {
    return (
        <React.Fragment>
            <div id="yandex_rtb_R-A-1691059-14"></div>
            <script>
                {window.yaContextCb.push(() => {
                    window.Ya.Context.AdvManager.render({
                        blockId: "R-A-1691059-14",
                        renderTo: "yandex_rtb_R-A-1691059-14"
                    })
                })}</script>
        </React.Fragment>
    )
}

const Idx5VideoSection = () => {
    return (
        <React.Fragment>
            <div id="yandex_rtb_R-A-1691059-15"></div>
            <script>
                {window.yaContextCb.push(() => {
                    window.Ya.Context.AdvManager.render({
                        blockId: "R-A-1691059-15",
                        renderTo: "yandex_rtb_R-A-1691059-15"
                    })
                })}</script>
        </React.Fragment>
    )
}

const Idx11VideoSection = () => {
    return (
        <React.Fragment>
            <div id="yandex_rtb_R-A-1691059-16"></div>
            <script>
                {window.yaContextCb.push(() => {
                    window.Ya.Context.AdvManager.render({
                        blockId: "R-A-1691059-16",
                        renderTo: "yandex_rtb_R-A-1691059-16"
                    })
                })}</script>
        </React.Fragment>
    )
}

const Idx14VideoSection = () => {
    return (
        <React.Fragment>
            <div id="yandex_rtb_R-A-1691059-17"></div>
            <script>
                {window.yaContextCb.push(() => {
                    window.Ya.Context.AdvManager.render({
                        blockId: "R-A-1691059-17",
                        renderTo: "yandex_rtb_R-A-1691059-17"
                    })
                })}</script>
        </React.Fragment>
    )
}

const Idx20VideoSection = () => {
    return (
        <React.Fragment>
            <div id="yandex_rtb_R-A-1691059-18"></div>
            <script>
                {window.yaContextCb.push(() => {
                    window.Ya.Context.AdvManager.render({
                        blockId: "R-A-1691059-18",
                        renderTo: "yandex_rtb_R-A-1691059-18"
                    })
                })}</script>
        </React.Fragment>
    )
}

const Idx33VideoSection = () => {
    return (
        <React.Fragment>
            <div id="yandex_rtb_R-A-1691059-19"></div>
            <script>
                {window.yaContextCb.push(() => {
                    window.Ya.Context.AdvManager.render({
                        blockId: "R-A-1691059-19",
                        renderTo: "yandex_rtb_R-A-1691059-19"
                    })
                })}</script>
        </React.Fragment>
    )
}

const Idx37VideoSection = () => {
    return (
        <React.Fragment>
            <div id="yandex_rtb_R-A-1691059-20"></div>
            <script>
                {window.yaContextCb.push(() => {
                    window.Ya.Context.AdvManager.render({
                        blockId: "R-A-1691059-20",
                        renderTo: "yandex_rtb_R-A-1691059-20"
                    })
                })}</script>
        </React.Fragment>
    )
}

const Idx56VideoSection = () => {
    return (
        <React.Fragment>
            <div id="yandex_rtb_R-A-1691059-21"></div>
            <script>
                {window.yaContextCb.push(() => {
                    window.Ya.Context.AdvManager.render({
                        blockId: "R-A-1691059-21",
                        renderTo: "yandex_rtb_R-A-1691059-21"
                    })
                })}</script>
        </React.Fragment>
    )
}

const Idx59VideoSection = () => {
    return (
        <React.Fragment>
            <div id="yandex_rtb_R-A-1691059-22"></div>
            <script>
                {window.yaContextCb.push(() => {
                    window.Ya.Context.AdvManager.render({
                        blockId: "R-A-1691059-22",
                        renderTo: "yandex_rtb_R-A-1691059-22"
                    })
                })}</script>
        </React.Fragment>
    )
}

const Idx70VideoSection = () => {
    return (
        <React.Fragment>
            <div id="yandex_rtb_R-A-1691059-23"></div>
            <script>
                {window.yaContextCb.push(() => {
                    window.Ya.Context.AdvManager.render({
                        blockId: "R-A-1691059-23",
                        renderTo: "yandex_rtb_R-A-1691059-23"
                    })
                })}</script>
        </React.Fragment>
    )
}

const Idx82VideoSection = () => {
    return (
        <React.Fragment>
            <div id="yandex_rtb_R-A-1691059-24"></div>
            <script>
                {window.yaContextCb.push(() => {
                    window.Ya.Context.AdvManager.render({
                        blockId: "R-A-1691059-24",
                        renderTo: "yandex_rtb_R-A-1691059-24"
                    })
                })}</script>
        </React.Fragment>
    )
}

const Idx93VideoSection = () => {
    return (
        <React.Fragment>
            <div id="yandex_rtb_R-A-1691059-25"></div>
            <script>
                {window.yaContextCb.push(() => {
                    window.Ya.Context.AdvManager.render({
                        blockId: "R-A-1691059-25",
                        renderTo: "yandex_rtb_R-A-1691059-25"
                    })
                })}</script>
        </React.Fragment>
    )
}



const adsObjVideoSection = {
    1: <Idx1VideoSection />,
    2: <Idx2VideoSection />,
    5: <Idx5VideoSection />,
    11: <Idx11VideoSection />,
    14: <Idx14VideoSection />,
    20: <Idx20VideoSection />,
    33: <Idx33VideoSection />,
    37: <Idx37VideoSection />,
    56: <Idx56VideoSection />,
    59: <Idx59VideoSection />,
    70: <Idx70VideoSection />,
    82: <Idx82VideoSection />,
    93: <Idx93VideoSection />,
}

export default adsObjVideoSection;
