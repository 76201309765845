import axios from "axios";
import {
    SET_CURRENT_VIDEO,
    GET_SECTION_VIDEOS_REQUEST,
    GET_SECTION_VIDEOS_SUCCESS,
    GET_SECTION_VIDEOS_FAIL,
    CLEAR_SECTION_VIDEOS,
    GET_SECTION_VIDEO_REQUEST,
    GET_SECTION_VIDEO_SUCCESS,
    GET_SECTION_VIDEO_FAIL,
    GET_RANDOM_VIDEOS_FAIL,
    GET_RANDOM_VIDEOS_SUCCESS,
    GET_RANDOM_VIDEOS_REQUEST,
    CLEAR_RANDOM_VIDEOS,
    CLEAR_HALF_RANDOM_VIDEOS
} from "../constants/videoConstants";

export const setCurrentVideo = (video) => (dispatch) => {
    dispatch({
        type: SET_CURRENT_VIDEO,
        payload: video,
    });
}

export const getSectionVideos = (sectionName, offset) => async (dispatch, getState) => {
    const { sectionVideosReducer } = getState();
    const { cancelToken } = sectionVideosReducer;

    if (cancelToken) {
        cancelToken.cancel('canceled by the user');
    }

    const newCancelToken = axios.CancelToken.source();

    try {
        dispatch({
            type: GET_SECTION_VIDEOS_REQUEST,
            payload: { cancelToken: newCancelToken }
        });

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
            params: {
                offset
            },
            cancelToken: newCancelToken.token
        };

        const { data } = await axios.get(`/api/videoSections/${sectionName}`, config);

        dispatch({
            type: GET_SECTION_VIDEOS_SUCCESS,
            payload: { ...data, sectionName }
        });

    } catch (error) {
        if (axios.isCancel(error)) {
            console.log('Request canceled', error.message);
        }
        else {
            dispatch({
                type: GET_SECTION_VIDEOS_FAIL,
                payload: error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
            });
        }
    }
}

export const getSectionVideosInitial = (sectionName) => async (dispatch, getState) => {
    try {
        const { sectionVideosReducer } = getState();
        const { sectionName: sectionNameRedux } = sectionVideosReducer;
        const { cancelToken } = sectionVideosReducer;
        const { videos } = sectionVideosReducer;

        if (sectionName === sectionNameRedux && videos.length > 0) {
            return;
        }

        if (cancelToken) {
            cancelToken.cancel('canceled by the user');
        }

        const newCancelToken = axios.CancelToken.source();

        dispatch({ type: CLEAR_SECTION_VIDEOS });
        dispatch({ type: CLEAR_RANDOM_VIDEOS });

        dispatch({
            type: GET_SECTION_VIDEOS_REQUEST,
            payload: { cancelToken: newCancelToken }
        });

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
            cancelToken: newCancelToken.token
        };

        const { data } = await axios.get(`/api/videoSections/${sectionName}`, config);

        dispatch({
            type: GET_SECTION_VIDEOS_SUCCESS,
            payload: { ...data, sectionName, cancelToken: newCancelToken }
        });

    } catch (error) {
        if (axios.isCancel(error)) {
            console.log('Request canceled', error.message);
        }
        else {
            dispatch({
                type: GET_SECTION_VIDEOS_FAIL,
                payload: error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
            });
        }
    }
}

export const getOneSectionVideoById = (sectionName, videoId) => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_SECTION_VIDEO_REQUEST,
        });

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };

        const { data } = await axios.get(`/api/videoSections/${sectionName}/${videoId}`, config);

        dispatch({
            type: GET_SECTION_VIDEO_SUCCESS,
            payload: data
        });

    } catch (error) {
        dispatch({
            type: GET_SECTION_VIDEO_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        });
    }
}

export const getRandomVideos = (offset) => async (dispatch, getState) => {

    try {
        const { sectionVideosReducer, randomVideosReducer } = getState();
        const { videos: sectionVideos } = sectionVideosReducer;
        const { videos: randomVideos } = randomVideosReducer;

        dispatch({
            type: GET_RANDOM_VIDEOS_REQUEST,
        });

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
            params: {
                alreadyLoadedQuantity: sectionVideos.length + randomVideos.length,
            }

        };

        const { data } = await axios.get(`/api/videoSections/random`, config);

        const sectionVideosIds = sectionVideos.map(video => video.id.videoId);
        const randomVideosIds = randomVideos.map(video => video.id.videoId);

        const alreadyLoaded = [...sectionVideosIds, ...randomVideosIds];

        const filteredVideos = await data.videos.filter(video => !alreadyLoaded.includes(video.id.videoId));

        if (filteredVideos.length <= 3) {
            /* console.log('less than 3, clearing half') */
            dispatch({
                type: CLEAR_HALF_RANDOM_VIDEOS,
            });
            return;
        }

        dispatch({
            type: GET_RANDOM_VIDEOS_SUCCESS,
            payload: {
                ...data,
                videos: filteredVideos
            }
        });

    } catch (error) {
        dispatch({
            type: GET_RANDOM_VIDEOS_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        });
    }
}