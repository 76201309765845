import React from "react";

import useWindowDimensions from "../../../WindowDimensions-hook/useWindowDimensions";
import ContextMenu from "../../../shared/Navigation/ContextMenu";

const TicketsSideBarMobileChecker = (props) => {
  const { width } = useWindowDimensions();
 
  if (width <= 600)
    return <ContextMenu he={props.he}>{(params) => props.children(params)}</ContextMenu>;
  else
    return (
      <>
        <ContextMenu>{() => { }}</ContextMenu>
        {props.children()}
      </>
    );

  /*  return (<><ContextMenu>{(params) => props.children(params)}</ContextMenu> {props.children()}</>) */
};

export default TicketsSideBarMobileChecker;
