import React from "react";
import { connect } from "react-redux";
import "./Alerts.css";
import Alert from "./Alert";

const Alerts = ({ alerts }) => {
  
  return (
    alerts !== null &&
    alerts.length > 0 &&
    alerts.map((alert, idx) => (
      <Alert
        key={alert.id}
        msg={alert.msg}
        idx={idx}
        alertType={alert.alertType}
        timeout={alert.timeout}
        img={alert.img}
      />
    ))
  );
};

const mapStateToProps = (state) => ({
  alerts: state.alertReducer,
});

export default connect(mapStateToProps)(Alerts);
