import moment from "moment";
const achivmentsHandler = (achivmentsForChange) => {
  if (!Array.isArray(achivmentsForChange))
    return console.log("в функцию achivmentsHandler надо передавать массив!!");
  let achivmentsLS = localStorage.getItem("achivments");
  if (achivmentsLS) {
    achivmentsLS = JSON.parse(achivmentsLS);
    let isComplete;
    let date;
    let completedAchivments = [];

    const findLength = (arr, category) => {
      return JSON.parse(arr).filter(
        (el) => el.ticketCategory === category && el.ticketStatus === true
      ).length;
    };

    const checkForComplete = (flag, achivmentName) => {
      if (flag) {
        isComplete = true;
        date = moment().format("DD/MM/YYYY");
        completedAchivments.push(achivmentName)
      } else {
        isComplete = false;
        date = null;
      }
    };

    const makeObj = (achivmentName, currentProgress, isComplete, date) => {
      return {
        ...achivmentsLS,
        [achivmentName]: {
          ...achivmentsLS[achivmentName],
          currentProgress: currentProgress,
          isComplete: isComplete,
          date: date,
        },
      };
    };

    const specialFuncForMasterABCD = (category, achivmentName) => {
        let ticketStatusArray = localStorage.getItem("ticketStatusArray");
        if (ticketStatusArray) {
          const length = findLength(ticketStatusArray, category);
          checkForComplete(
            length >= achivmentsLS[achivmentName].forComplete, achivmentName
          );
          achivmentsLS = makeObj(achivmentName, length, isComplete, date);
        }
    }

    achivmentsForChange.forEach((achivmentName) => {
      // проверям получина ли ачивка
      if (achivmentsLS[achivmentName].isComplete === false) {
        switch (achivmentName) {
          case "masterAB":
            specialFuncForMasterABCD("ticketsAB", achivmentName)
            break;
          case "masterCD":
            specialFuncForMasterABCD("ticketsCD", achivmentName)
            break;
          default:
            // проверяем currentProgress больше или равен forComplete
            // если да, то перезаписываем объект. Ставим дату и искомплит в тру
            checkForComplete(achivmentsLS[achivmentName].currentProgress + 1 >=
              achivmentsLS[achivmentName].forComplete, achivmentName)
            // делаем новый объект с увеличенным currentProgress и датой с искомплит
            achivmentsLS = makeObj(
              achivmentName,
              achivmentsLS[achivmentName].currentProgress + 1,
              isComplete,
              date
            );
        }
      }
    });
    localStorage.setItem("achivments", JSON.stringify(achivmentsLS));
    return completedAchivments;
  }
};

export default achivmentsHandler;
