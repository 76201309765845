import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import './video.css';
import { setCurrentVideo } from '../../store/actions/videoActions';
import { useDispatch } from 'react-redux';

const formatTitle = (title) => {
    const maxLength = 75 // Maximum characters for the title
    if (title.length > maxLength) {
        return `${title.slice(0, maxLength)}...`; // Truncate if too long
    }
    return title;
}

const formatPublishedAt = (publishedAt) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(publishedAt).toLocaleDateString(undefined, options);
}

const VideoPreviewMini = (props) => {
    const dispatch = useDispatch();
    const { thumbnailUrl, title, channelName, publishedAt, videoId, description } = props;

    const handleClick = () => {
        dispatch(setCurrentVideo({ videoId, thumbnailUrl, title, channelName, publishedAt, description }));
    }

    return (
        <Link className="video-preview-mini" to={`/category/${videoId}/`}>
            <div onClick={handleClick}>
                <div className="thumbnail">
                    <img src={thumbnailUrl} alt="Video Thumbnail" />
                </div>
                <div className="info">
                    <h2 className="title">{formatTitle(title)}</h2>
                    <p className="channel">{channelName}</p>
                    <p className="publishedAt">{formatPublishedAt(publishedAt)}</p>
                </div>
            </div>
        </Link>
    );
}

const VideoPreviewMaxi = (props) => {
    const [isImgExist, setIsImgExist] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const dispatch = useDispatch();
    const { thumbnailUrl, title, channelName, publishedAt, videoId, description } = props;

    const handleClick = () => {
        dispatch(setCurrentVideo({ videoId, thumbnailUrl, title, channelName, publishedAt, description }));
    }

    const onLoad = (e) => {
        const naturalWidth = e.target.naturalWidth;
        const naturalHeight = e.target.naturalHeight;
        if (naturalWidth === 120 && naturalHeight === 90) {
            setIsImgExist(false);
        }
        setLoaded(true);
    }

    return (
        <>
            {isImgExist && <Link className="video-preview-maxi" to={`/category/${props.sectionName}/${videoId}/`} onClick={() => { window.scrollTo(0, 0) }}>
                <div onClick={handleClick}>
                    <div className="thumbnail">
                        {!loaded && (
                            <div
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    borderRadius: '10px',
                                    backgroundColor: 'lightgrey', // Grey placeholder color
                                }}
                            > </div>

                        )}
                        <img
                            src={thumbnailUrl}
                            alt="Превью картинка для видео"
                            onLoad={(e) => onLoad(e)}
                            loading="lazy"
                        />
                    </div>
                    <div className="info">
                        <h2 className="title">{formatTitle(title)}</h2>
                        <p className="channel">{channelName}</p>
                        <p className="publishedAt">{formatPublishedAt(publishedAt)}</p>
                    </div>
                </div>
            </Link>}
            {!isImgExist && props.videosArrLength < 40 && <div className='video-preview-maxi-skeleton'>
            </div>}
        </>
    );
}


export { VideoPreviewMaxi, VideoPreviewMini }


