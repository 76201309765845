const achivmentsData = [
  {
    name: "niceStart",
    header: "Хороший старт",
    url: `/images/achivments/niceStart.png`,
    description: 'Завершить тест 10 раз в режиме "Билеты ПДД"',
  },
  {
    name: "keepItUp",
    header: "Так держать!",
    url: `/images/achivments/keepItUp.png`,
    description: 'Завершить тест 20 раз в режиме "Билеты ПДД"',
  },
  {
    name: "forty",
    header: "40 билетов!",
    url: `/images/achivments/forty.png`,
    description: 'Завершить тест 40 раз в режиме "Билеты ПДД"',
  },
  {
    name: "masterAB",
    header: "Мастер AB",
    url: `/images/achivments/masterAB.png`,
    description: "Решить верно все билеты AB",
  },
  {
    name: "masterCD",
    header: "Мастер CD",
    url: `/images/achivments/masterCD.png`,
    description: "Решить верно все билеты CD",
  },
  {
    name: "badAss",
    header: "Двоечник",
    url: `/images/achivments/badAss.png`,
    description: "Совершить 300 ошибок",
  },
  {
    name: "excellentKnowledge",
    header: "Отличные знания",
    url: `/images/achivments/excellentKnowledge.png`,
    description: "Решить экзамен верно 50 раз",
  },
  {
    name: "hundredExams",
    header: "Сто экзаменов",
    url: `/images/achivments/hundredExams.png`,
    description: "Пройти до конца 100 экзаменов",
  },
  {
    name: "friend",
    header: "Наш человек",
    url: `/images/achivments/friend.png`,
    description: "Посетить нашу группу ВК",
  },
  {
    name: "errorsResolve",
    header: "Работа над ошибками",
    url: `/images/achivments/errorsResolve.png`,
    description: "Прорешать 100 ошибок",
  },
  {
    name: "fast",
    header: "Быстрый",
    url: `/images/achivments/fast.png`,
    description: 'Решить билет верно за 2 минуты',
  },
  {
    name: "superFast",
    header: "Очень быстрый",
    url: `/images/achivments/superFast.png`,
    description: 'Решить билет верно за минуту',
  },
];
export default achivmentsData;
