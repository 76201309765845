export const SET_CLICKED_ANSWER_ID = 'SET_CLICKED_ANSWER_ID';
export const SET_NUMBERS_STATE_ARRAY = 'SET_NUMBERS_STATE_ARRAY';
export const RESET_NUMBERS_STATE_ARRAY = 'RESET_NUMBERS_STATE_ARRAY';
export const SET_TICKET_STATUS_ARRAY = 'SET_TICKET_STATUS_ARRAY';
export const RESET_TICKET_STATUS_ARRAY = 'RESET_TICKET_STATUS_ARRAY';

export const SET_TICKETS_ARRAY = 'SET_TICKETS_ARRAY';
export const SET_LOADED_BILET = 'SET_LOADED_BILET';
export const RESET_TO_INITIAL_STATE = 'RESET_TO_INITIAL_STATE';
export const SET_SHOULD_RESET_TIMER = 'SET_SHOULD_RESET_TIMER';
export const SET_SHOULD_ADD_FIVE_MINUTES = 'SET_SHOULD_ADD_FIVE_MINUTES';
export const SET_SHOULD_UPDATE_EXAM_STATS = 'SET_SHOULD_UPDATE_EXAM_STATS';

export const SET_RESULT_TIMER = 'SET_RESULT_TIMER';
export const SET_DEADLINE = 'SET_DEADLINE';
export const SET_TIMER_STARTS_AT = 'SET_TIMER_STARTS_AT';

export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

