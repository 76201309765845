import React from "react";
import ContextMenu from "../shared/Navigation/ContextMenu";
import { Link } from "react-router-dom";

const NotFound = (props) => {
  return (
    <>
      <ContextMenu notRenderBurgerMenuIcon={true}>{() => { }}</ContextMenu>

      <p className="center-text" style={{ marginTop: "6rem" }}>
        404 Not found
      </p>
      <p className="center-text">{props.children}</p>
      <Link
        to={"/"}
        className="next-button"
        style={{ margin: "0 auto", display: "block" }}
      >
        Вернуться на главную
      </Link>
    </>
  );
};

export default NotFound;
