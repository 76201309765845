import React from 'react';
import { Link } from "react-router-dom";
import './Footer.css';
import { RESET_TO_INITIAL_STATE } from '../../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import MobileButtons from '../../../main/components/MobileButtons/MobileButtons';

import VkGroupClick from '../VkGroupClick'

const Footer = () => {
    const categoryTickets = useSelector(state => state.router.location.pathname.split("/")[1]);
    const dispatch = useDispatch();

    const resetToInitialState = () => {
        //this function only for tickets pages
        if (categoryTickets === "errorsAB" || categoryTickets === "errorsCD") {
            let errorsArray = JSON.parse(localStorage.getItem("errorsArray"));
            if (errorsArray !== null) {
                errorsArray = errorsArray.filter((el) => el.ticketErrors.length !== 0);
                if (errorsArray.length === 0) localStorage.removeItem("errorsArray");
                else {
                    localStorage.setItem("errorsArray", JSON.stringify(errorsArray));
                }
            }
        }
        window.scrollTo(0, 0);
        dispatch({ type: RESET_TO_INITIAL_STATE });
    }

    return (
        <footer className='footer'>
            <div className='footer-container'>
                <div className='footer-row'>
                    {/* <h5 className='footer-heading'>pddportal.ru</h5> */}
                    <div className='footer-column'>
                        <h3 className='footer-heading'>Обучение ПДД</h3>
                        <ul className='footer-list'>
                            <li className='footer-list-item' onClick={resetToInitialState}>
                                <Link
                                    className="footer-link"
                                    to={`/examAB/1/`}
                                >
                                    Экзамен AB
                                </Link>
                            </li>
                            <li className='footer-list-item' onClick={resetToInitialState}>
                                <Link
                                    className="footer-link"
                                    to={`/examCD/1/`}
                                >
                                    Экзамен CD
                                </Link>
                            </li>
                            <li className='footer-list-item' onClick={resetToInitialState}>
                                <Link
                                    className="footer-link"
                                    to={`/ticketsAB/1/1/`}
                                >
                                    Билеты AB
                                </Link>
                            </li>
                            <li className='footer-list-item' onClick={resetToInitialState}>
                                <Link
                                    className="footer-link"
                                    to={`/ticketsCD/1/1/`}
                                >
                                    Билеты CD
                                </Link>
                            </li>
                            <li className='footer-list-item' onClick={resetToInitialState}>
                                <Link
                                    className="footer-link"
                                    to={`/themeAB/1/1/`}
                                >
                                    Вопросы по темам
                                </Link>
                            </li>
                            <li className='footer-list-item' onClick={resetToInitialState}>
                                <Link
                                    className="footer-link"
                                    to={`/rules/1/`}
                                >
                                    Теория ПДД
                                </Link>
                            </li>
                            <li className='footer-list-item' onClick={resetToInitialState}>
                                <Link
                                    className="footer-link"
                                    to={`/signs/1/`}
                                >
                                    Дорожные знаки
                                </Link>
                            </li>
                            <li className='footer-list-item' onClick={resetToInitialState}>
                                <Link
                                    className="footer-link"
                                    to={`/marks/1/`}
                                >
                                    Дорожная разметка
                                </Link>
                            </li>
                            <li className='footer-list-item' onClick={resetToInitialState}>
                                <Link
                                    className="footer-link"
                                    to={`/access/1/`}
                                >
                                    Допуск ТС к эксплуатации
                                </Link>
                            </li>
                        </ul>
                    </div>

                    <div className='footer-column'>
                        <h3 className='footer-heading'>Видеораздел</h3>
                        <ul className='footer-list'>
                            <li className='footer-list-item' onClick={window.scrollTo(0, 0)}>
                                <Link
                                    className="footer-link"
                                    to={`/videos/`}

                                >
                                    Подборка
                                </Link>
                            </li>
                            <li className='footer-list-item' onClick={window.scrollTo(0, 0)}>
                                <Link
                                    className="footer-link"
                                    to={`/category/driving-school/`}

                                >
                                    Автошкола
                                </Link>
                            </li>
                            <li className='footer-list-item' onClick={window.scrollTo(0, 0)}>
                                <Link
                                    className="footer-link"
                                    to={`/category/dtp-razbor/`}

                                >
                                    Разбор ДТП
                                </Link>
                            </li>
                            <li className='footer-list-item' onClick={window.scrollTo(0, 0)}>
                                <Link
                                    className="footer-link"
                                    to={`/category/car-info/`}

                                >
                                    Устройство атомобиля
                                </Link>
                            </li>
                            <li className='footer-list-item' onClick={window.scrollTo(0, 0)}>
                                <Link
                                    className="footer-link"
                                    to={`/category/auto-review/`}

                                >
                                    Автообзоры
                                </Link>
                            </li>
                            <li className='footer-list-item' onClick={window.scrollTo(0, 0)}>
                                <Link
                                    className="footer-link"
                                    to={`/category/dtp/`}

                                >
                                    Аварии
                                </Link>
                            </li>
                            <li className='footer-list-item' onClick={window.scrollTo(0, 0)}>
                                <Link
                                    className="footer-link"
                                    to={`/category/law`}

                                >
                                    Юридические вопросы
                                </Link>
                            </li>
                            <li className='footer-list-item' onClick={window.scrollTo(0, 0)}>
                                <Link
                                    className="footer-link"
                                    to={`/category/show/`}

                                >
                                    Шоу
                                </Link>
                            </li>
                            <li className='footer-list-item' onClick={window.scrollTo(0, 0)}>
                                <Link
                                    className="footer-link"
                                    to={`/category/bloggers/`}

                                >
                                    Автоблогеры
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className='footer-column'>
                        <h3 className='footer-heading'>Дополнительно</h3>
                        <ul className='footer-list'>
                            <li className='footer-list-item'>
                                <div className='footer-link'>
                                    <VkGroupClick >
                                        Мы в <i className="fab fa-vk"></i>
                                    </VkGroupClick>
                                </div>
                            </li>
                            <li className='footer-list-item'>
                                <MobileButtons />
                            </li>
                            <li>
                                <div className='footer-logo-item'>
                                    <Link to={`/`}>
                                        <h4 className='footer-logo-header'>pddportal.ru</h4>
                                    </Link>
                                    <Link to={`/`}>
                                        <img className='footer-logo-img' src="/images/icons/trafficlight.png" alt="pddportal" />
                                    </Link>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <p className='footer-text'>
                        Правила Дорожного Движения России - ваш путь к безопасной дороге. Сайт предоставляется исключительно в информационных целях и не заменяет официальный учебный материал. Учебник ПДД РФ 2024: Тесты, Экзамен, Теория и Дорожные Знаки предоставляется с целью подготовки к экзамену в ГАИ и увеличения уровня знаний по Правилам Дорожного Движения. Вся информация на сайте актуальна на 2024 год.
                    </p>
                </div>
            </div>

            <div className='footer-bottom'>
                © 2024 ПДД РФ 2024: Тесты, Экзамен, Теория и Дорожные Знаки
            </div>
        </footer>
    );
};

export default Footer;
