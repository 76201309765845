export const SET_CURRENT_ARTICLE = 'SET_CURRENT_ARTICLE';
export const GET_SECTION_ARTICLES_REQUEST = 'GET_SECTION_ARTICLES_REQUEST';
export const GET_SECTION_ARTICLES_SUCCESS = 'GET_SECTION_ARTICLES_SUCCESS';
export const GET_SECTION_ARTICLES_FAIL = 'GET_SECTION_ARTICLES_FAIL';
export const CLEAR_SECTION_ARTICLES = 'CLEAR_SECTION_ARTICLES';
export const ADD_OFFSET_TO_SECTION_ARTICLES = 'ADD_OFFSET_TO_SECTION_ARTICLES';

export const GET_SECTION_ARTICLE_REQUEST = 'GET_SECTION_ARTICLE_REQUEST';
export const GET_SECTION_ARTICLE_SUCCESS = 'GET_SECTION_ARTICLE_SUCCESS';
export const GET_SECTION_ARTICLE_FAIL = 'GET_SECTION_ARTICLE_FAIL';

export const GET_RANDOM_ARTICLES_REQUEST = 'GET_RANDOM_ARTICLES_REQUEST';
export const GET_RANDOM_ARTICLES_SUCCESS = 'GET_RANDOM_ARTICLES_SUCCESS';
export const GET_RANDOM_ARTICLES_FAIL = 'GET_RANDOM_ARTICLES_FAIL';
export const CLEAR_RANDOM_ARTICLES = 'CLEAR_RANDOM_ARTICLES';
export const CLEAR_HALF_RANDOM_ARTICLES = 'CLEAR_HALF_RANDOM_ARTICLES';