import React, { useEffect } from 'react';
import ArticleSnippet from './ArticleSnippet';

import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getSectionArticles, getSectionArticlesInitial } from '../../store/actions/articleActions';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from '../../shared/components/Loader';
import { SPECIAL_INDEXIES as specialIndexies } from '../../constants/otherConstants'
import adsObjArticleSection from './AdArticleSection'

import "../../video-section/components/video.css"

function Articles({ limitForShow, shouldShuffle, hideFromOffset }) {

    const dispatch = useDispatch();
    const history = useHistory();
    const sectionName = history.location.pathname.split('/')[2];

    const { loading, articles, error, hasMore } = useSelector((state) => state.sectionArticlesReducer);
    let displayedArticles;

    if (limitForShow && limitForShow < articles.length) {
        displayedArticles = articles.slice(0, limitForShow);
    }

    if (shouldShuffle) {
        if (Array.isArray(displayedArticles) && displayedArticles.length > 0) {
            displayedArticles = displayedArticles.sort(() => Math.random() - 0.5);
        }
        else displayedArticles = articles.sort(() => Math.random() - 0.5);
    }

    useEffect(() => {
        const offset = 0;
        dispatch(getSectionArticlesInitial(sectionName, offset));
    }, [dispatch, sectionName]);


    const fetchMoreArticles = () => {
        const offset = articles.length;
        dispatch(getSectionArticles(sectionName, offset));
    };



    return (
        <>
            {loading && articles.length === 0 && <Loader
                type="Oval"
                color="#00BFFF"
                height={90}
                width={90}
                style={{ margin: "30px auto", display: "block", textAlign: "center" }}
            />}

            {
                limitForShow && <>
                    {displayedArticles && displayedArticles.map((article, index) => {
                        const snippet = {
                            id: article.id,
                            title: article.title,
                            description: article.description,
                            image: article.image,
                            date: article.date,
                            authorName: article.author.name,
                            authorAvatar: article.author.avatar,
                            markdown: article.markdown,
                            urlName: article.urlName,
                            sectionName: sectionName,
                        }
                        if (specialIndexies.includes(index)) {

                            return (
                                <React.Fragment key={article.id}>
                                    <div className='special-preview-block video-preview-maxi'>
                                        {adsObjArticleSection[index]}
                                    </div>
                                    <ArticleSnippet
                                        key={index}
                                        snippet={snippet} />
                                </React.Fragment>
                            );
                        } else {
                            return <ArticleSnippet
                                key={index}
                                snippet={snippet} />
                        }
                    })}
                </>
            }

            {!limitForShow &&
                <InfiniteScroll
                    style={{ overflow: "visible" }}
                    dataLength={(articles.length) || 0}// This is important
                    next={fetchMoreArticles}
                    hasMore={hasMore}
                    children={articles}
                    loader={<Loader
                        type="Oval"
                        color="#00BFFF"
                        height={90}
                        width={90}
                        style={{ margin: "30px auto", display: "block", textAlign: "center" }}
                    />}

                >

                    <div className='articles'>
                        {articles && articles.map((article, index) => {
                            if (hideFromOffset && index < hideFromOffset) return null;
                            const snippet = {
                                id: article.id,
                                title: article.title,
                                description: article.description,
                                image: article.image,
                                date: article.date,
                                authorName: article.author.name,
                                authorAvatar: article.author.avatar,
                                markdown: article.markdown,
                                urlName: article.urlName,
                                sectionName: sectionName,
                                index: index,
                            }
                            if (specialIndexies.includes(index)) {
                                return (
                                    <React.Fragment key={article.id}>
                                        <div className='special-article-block  mb-22'>
                                        
                                            {adsObjArticleSection[index]}
                                        </div>
                                        <ArticleSnippet
                                            key={index}
                                            snippet={snippet} />
                                    </React.Fragment>
                                );
                            } else {
                                return <ArticleSnippet
                                    key={index}
                                    snippet={snippet} />
                            }
                        }
                        )}
                    </div>

                </InfiniteScroll>
            }

            {error && <div>{error}</div>}

        </>

    );
}

export default Articles;

/* {dataMarkdown.map((item, index) => {
    return <OneArticle key={index} article={item} />
}


<OneArticle
article={data[0].markdown}
author={data[0].author && data[0].author}
date={data[0].date} /> 


)} */


/* <ArticlesLayout>
<div className="articles">
    {data.map((item, index) => {
        const snippet = {
            id: item.id,
            title: item.title,
            description: item.description,
            image: item.image,
            date: item.date,
        }
        return <ArticleSnippet key={index} snippet={snippet} />
    }
    )}
</div>
</ArticlesLayout> */

/* const dataMarkdown = [`
# Правила парковки в городе: Как избежать штрафов и обеспечить беспроблемную стоянку

![Парковка](https://images.unsplash.com/photo-1506521781263-d8422e82f27a?q=80&w=4170&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)

Парковка в городе может быть настоящим испытанием для водителей, особенно в условиях ограниченного пространства и постоянного движения. Однако знание основных правил парковки не только помогает избежать неприятных ситуаций, но и сделать вашу поездку более удобной. В этой статье мы рассмотрим основные правила парковки в городе и дадим несколько советов по успешной стоянке.

## 1. Соблюдение дорожных знаков
Первое и самое важное правило — внимательно следить за дорожными знаками и маркировкой на улицах. Они указывают на разрешенные и запрещенные места для парковки. Не игнорируйте знаки "Запрещено парковать" и "Остановка запрещена", чтобы избежать штрафов.

## 2. Расстояние от гидранта и перекрестка
Соблюдайте необходимое расстояние от гидранта и перекрестка при парковке. Обычно требуется не менее 5 метров от гидранта и 10 метров от перекрестка. Это правило способствует безопасности и обеспечивает проходимость дороги.

## 3. Платные зоны парковки
В некоторых городах существуют платные зоны парковки. Обращайте внимание на знаки с информацией о платной парковке, чтобы избежать штрафов. Используйте соответствующие тарифы и сроки оплаты.

## 4. Парковка на тротуаре
Парковка на тротуаре обычно запрещена, так как это создает препятствие для пешеходов. Оставляйте автомобиль только на предназначенных для этого местах. Иначе, вы можете столкнуться с штрафом и трудностями для прохождения пешеходов.

## 5. Внимание к ограничениям времени
Некоторые участки парковки имеют ограничения времени стоянки. Учтите эти ограничения, чтобы избежать штрафов за превышение времени на одном месте.

## 6. Правила парковки во дворах и на общественных местах
Если у вас есть необходимость парковать во дворах или на частных территориях, убедитесь, что у вас есть соответствующее разрешение от владельца или управляющей компании. Незаконная парковка может привести к эвакуации вашего автомобиля.

## 7. Парковка для инвалидов
Уважайте зоны парковки, предназначенные для инвалидов. Использование таких мест без соответствующего разрешения может повлечь за собой серьезные штрафы.

## 8. Параллельная парковка
При параллельной парковке старайтесь оставаться на безопасном расстоянии от других автомобилей. Это упростит процесс парковки и избежит повреждений.

## 9. Включите аварийную сигнализацию
Включайте аварийную сигнализацию при парковке на ограниченном пространстве или в местах, где ваш автомобиль может быть незаметен другим участникам движения.

## 10. Специальные зоны для велосипедов и мотоциклов
Учитывайте специальные зоны для велосипедов и мотоциклов. Не паркуйтесь на местах, предназначенных для других видов транспорта.


Соблюдение правил парковки в городе не только помогает поддерживать порядок на дорогах, но и предотвращает штрафы и неудобства для вас и других водителей. Помните, что уважение правил — это вклад в общую безопасность и порядок на городских улицах.

    `] */
/* 
    const data = [
        {
            id: 1,
            urlName: "pravila_parkovki_v_gorode_date",
            title: 'Правила парковки в городе: Как избежать штрафов и обеспечить беспроблемную стоянку',
            description: 'Парковка в городе может быть настоящим испытанием для водителей, особенно в условиях ограниченного пространства и постоянного движения. ',
            image: 'https://images.unsplash.com/photo-1506521781263-d8422e82f27a?q=80&w=4170&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
            date: '2021-01-01',
            author: { name: 'Pddportal', avatar: './images/icons/logo192.png' },
            markdown: dataMarkdown[0]
        },
        {
            id: 2,
            urlName: "pravila_parkovki_v_gorode_date",
            title: 'Правила парковки в городе: Как избежать штрафов и обеспечить беспроблемную стоянку',
            description: 'Парковка в городе может быть настоящим испытанием для водителей, особенно в условиях ограниченного пространства и постоянного движения. ',
            image: 'https://images.unsplash.com/photo-1506521781263-d8422e82f27a?q=80&w=4170&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
            date: '2021-01-01',
            author: { name: 'Pddportal', avatar: './images/icons/logo192.png' },
            markdown: dataMarkdown[0]
        },
        {
            id: 3,
            urlName: "pravila_parkovki_v_gorode_date",
            title: 'Правила парковки в городе: Как избежать штрафов и обеспечить беспроблемную стоянку',
            description: 'Парковка в городе может быть настоящим испытанием для водителей, особенно в условиях ограниченного пространства и постоянного движения. ',
            image: 'https://images.unsplash.com/photo-1506521781263-d8422e82f27a?q=80&w=4170&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
            date: '2021-01-01',
            author: { name: 'Pddportal', avatar: './images/icons/logo192.png' },
            markdown: dataMarkdown[0]
        }
    ] */