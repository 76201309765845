// highlightsReducer.js
import {
    GET_TASKS_STATS_REQUEST,
    GET_TASKS_STATS_SUCCESS,
    GET_TASKS_STATS_FAIL,
} from "./constants/highlightsConstants";

const initialState = {
    loading: false,
    error: null,
    tasksStats: null
};

export const highlightsTasksReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_TASKS_STATS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case GET_TASKS_STATS_SUCCESS:
            return {
                ...state,
                loading: false,
                tasksStats: payload
            };
        case GET_TASKS_STATS_FAIL:
            return {
                ...state,
                loading: false,
                error: payload
            };
        default:
            return state;
    }
}