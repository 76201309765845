import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";

/* import { useStore } from "../../hooks-store/store"; */
import Countdown, { zeroPad } from 'react-countdown';
import * as actionTypes from "../../store/actions";

const renderer = ({ total, minutes, seconds }) => {
  if (total <= 0) {
    return (
      <h3 style={{ color: "tomato", textAlign: "center" }}>
        00:00
      </h3>)
  } else
    return <div className="timer">{zeroPad(minutes)}:{zeroPad(seconds)}</div>
};

const Timer = (props) => {
  /*   const dispatchCustomStore = useStore(false)[1]; */
  const dispatch = useDispatch()
  const shouldAddFiveMinutes = useSelector((state) => state.ticketsReducer.shouldAddFiveMinutes);
  const shouldResetTimer = useSelector((state) => state.ticketsReducer.shouldResetTimer);

  const [completions, setCompletions] = useState(1);
  const [deadline, setDeadline] = useState(1200); // 1200 = 20 минут, надо чтобы 1200 совпадало с редюсером
  const additionTime = 300 // 5 min
  const [timerStartsAt, setTimerStartAt] = useState(Date.now())
  const [milSeconds, setMilSeconds] = useState(Date.now() + deadline * 1000)

  useEffect(() => {
    /* console.log("таймер диспатчит время старта", timerStartsAt) */
    dispatch({
      type: actionTypes.SET_TIMER_STARTS_AT,
      timerStartsAt: timerStartsAt,
    })
  }, [dispatch, timerStartsAt]);

  useEffect(() => {
    if (shouldAddFiveMinutes) {
      setMilSeconds((lastState) => lastState + (additionTime * 1000))
      dispatch({
        type: actionTypes.SET_SHOULD_ADD_FIVE_MINUTES,
        shouldAddFiveMinutes: false,
      })
      dispatch({
        type: actionTypes.SET_DEADLINE,
        deadline: deadline + additionTime, // +5 min
      })
      setDeadline(deadline + additionTime)
    }
  }, [timerStartsAt, shouldAddFiveMinutes, dispatch, deadline]);

  // reset timer 
  useEffect(() => {
    const resetDeadLine = 1200 // 20 min
    if (shouldResetTimer) {
      setCompletions(prev => prev + 1)
      setDeadline(resetDeadLine)
      setTimerStartAt(Date.now())
      setMilSeconds(Date.now() + resetDeadLine * 1000)
      dispatch(dispatch({
        type: actionTypes.SET_SHOULD_RESET_TIMER,
        shouldResetTimer: false,
      }))
    }
  }, [dispatch, shouldResetTimer]);

  /*   const onComplete = () => {
      setCompletions("2")
      console.log('completions', completions)
    } */

  return (
    <div style={{ color: props.timerColor }}>
      <Countdown
        key={completions}
        date={milSeconds}
        renderer={renderer}
      >
      </Countdown>
    </div>
  );
}

export default Timer;

/* onTick={(tik) => onTick(tik)} */