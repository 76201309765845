import React from 'react';


const GridVideoSkeletonLoader = () => {
    const quantity = 9;

    return (
        <div className='video-section'>
            {
                Array(quantity).fill().map((item, index) => (
                    <div key={index} className='video-preview-maxi-skeleton'>
                    </div>
                ))
            }
        </div>
    );
};

export default GridVideoSkeletonLoader;
