import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import ticketsReducer from "./ticketsReducer";
import alertReducer from "./alertReducer";
import { videoReducer, sectionVideosReducer, randomVideosReducer } from "./videoReducer";
import { articleReducer, sectionArticlesReducer, randomArticlesReducer } from "./articleReducer";
import { highlightsReducer } from './highlightsReducer';
import { highlightsTasksReducer } from './highlightsTasksReducer';

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  ticketsReducer,
  alertReducer,
  videoReducer,
  sectionVideosReducer,
  randomVideosReducer,
  articleReducer,
  sectionArticlesReducer,
  randomArticlesReducer,
  highlightsReducer,
  highlightsTasksReducer
})

export default createRootReducer