import LoaderFromLib from "react-loader-spinner";
import React from "react";

function Loader(props) {
  const renderFunc = () => {
    if (props.center)
      /* return (
        <LoaderFromLib
          type="Oval"
          color="#00BFFF"
          height={120}
          width={120}
          style={{ position: "absolute", top: "50%", left: "50%", marginTop: "-60px", marginLeft: "-60px" }}
        />
      ); */
      return ( <div style={{ position: "absolute", top: "50%", left: "50%", marginTop: "-60px", marginLeft: "-60px" }}>
        <Loader
          type="Oval"
          color="#00BFFF"
          height={120}
          width={120}
          style={{ margin: "5px auto", display: "block", textAlign: "center" }}
        />
        <p className="center-text">Загружаем модуль</p>
      </div>)
    else if (props.loadingTickets) {
      return (<>
        <Loader
          type="Oval"
          color="#00BFFF"
          height={90}
          width={90}
          style={{ margin: "30px auto", display: "block", textAlign: "center" }}
        />
        <p className="center-text">Загружаем билеты</p>
      </>)
    }
    else
      return (
        <LoaderFromLib
          type={props.type}
          color={props.color}
          height={props.height}
          width={props.width}
          style={props.style}
        />
      );
  };

  return renderFunc();
}

export default Loader;
